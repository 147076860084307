import React, { useContext, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import firebase from 'firebase';
import { Select, Modal } from 'antd';
import styled from 'styled-components';
import { includes } from 'lodash';
import {
  ItemDetailsColumnWrapper, ItemDetailsLabel, ViewTypeRadioButton, ViewTypeRadioGroup,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { currentShopOrderAtom } from 'shared/state/orderState';
import theme from 'shared/theme';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { IRunner, IRunnerHistory } from '../../../types';

const HoldSelectColumnWrapper = styled(ItemDetailsColumnWrapper)`
  margin: 0 0 0 16px;
`;
export const ActiveTypeSelect = styled(Select)`
  width: 144px;
  border-radius: 8px;

  & .ant-select-selector {
    height: 32px;
    line-height: 32px;
  }

  @media ${theme.device.laptopL} {
    display: none;
  }
`;
const HoldRadioGroup = styled(ViewTypeRadioGroup)`
  margin-left: 0;
  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: ${(props: any) => (props.hold ? theme.palette.warning.hue : theme.palette.primary.hue)};
    border-color: ${(props: any) => (props.hold ? theme.palette.warning.hue : theme.palette.primary.hue)};
  }
`;

const OrderHoldStatus = () => {
  const { currentUser } = useContext(AuthContext);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const [holdChange, setHoldChange] = useState(false);
  const [showHoldModal, setShowHoldModal] = useState(false);
  const [tempHoldNotes, setTempHoldNotes] = useState('');
  const [pendingHoldValue, setPendingHoldValue] = useState<boolean | null>(null);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);

  const updateLastHistoryTimestamp = (history: IRunnerHistory[]) => {
    const now = new Date();
    const last = { step: history[history.length - 1].step, dateEntered: firebase.firestore.Timestamp.fromDate(now) };
    return [...history.slice(0, history.length - 1), last];
  };
  const onChangeActive = (e: any) => {
    if (!e) return;
    const value = e.target ? e.target.value : e;

    if (value === true) {
      setPendingHoldValue(value);
      setShowHoldModal(true);
      return;
    }

    updateOrderHoldStatus(value, null);
  };

  const updateOrderHoldStatus = (holdValue: boolean, notes: string | null) => {
    let runners = currentShopOrder.runners;
    setHoldChange(!holdChange);

    if (!holdValue) {
      runners = currentShopOrder.runners.map((r: IRunner) => ({
        ...r,
        history: updateLastHistoryTimestamp(r.history),
      }));
    }

    setCurrentShopOrder({
      ...currentShopOrder,
      hold: holdValue,
      holdNotes: notes,
      runners,
    });
  };

  const handleModalOk = () => {
    if (!tempHoldNotes.trim()) return;
    updateOrderHoldStatus(true, tempHoldNotes);
    setShowHoldModal(false);
    setTempHoldNotes('');
    setPendingHoldValue(null);
  };

  const handleModalCancel = () => {
    setShowHoldModal(false);
    setTempHoldNotes('');
    setPendingHoldValue(null);
  };

  return (
    <>
      {currentShopOrder && (
      <HoldSelectColumnWrapper key="order-topped-or-bound-details-wrapper" width="180px">
        <ItemDetailsLabel key="order-topped-or-bound-details-label">Job status</ItemDetailsLabel>
        <ActiveTypeSelect
          disabled={!includes([...superAdminUsers.emails, ...adminUsers.emails], currentUser.email)}
          size="middle"
          value={currentShopOrder.hold ?? false}
          onChange={onChangeActive}
        >
          <ViewTypeSelectOption value>On Hold</ViewTypeSelectOption>
          <ViewTypeSelectOption value={false}>Active</ViewTypeSelectOption>
        </ActiveTypeSelect>
        <HoldRadioGroup
          disabled={!includes([...superAdminUsers.emails, ...adminUsers.emails], currentUser.email)}
          hold={currentShopOrder.hold ?? false}
          value={currentShopOrder.hold ?? false}
          buttonStyle="solid"
          onChange={onChangeActive}
        >
          <ViewTypeRadioButton
            value
          >
            On Hold
          </ViewTypeRadioButton>
          <ViewTypeRadioButton
            value={false}
          >
            Active
          </ViewTypeRadioButton>
        </HoldRadioGroup>
      </HoldSelectColumnWrapper>
      )}

      <Modal
        title="Order Hold Reason"
        open={showHoldModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: !tempHoldNotes.trim() }}
      >
        <DetailTextAreaWithCallback
          id="hold-notes"
          label="Why is this order being put on hold?"
          placeholder="Enter reason for hold..."
          value={tempHoldNotes}
          changeCallback={setTempHoldNotes}
          blurCallback={() => {}}
        />
      </Modal>
    </>
  );
};

export default OrderHoldStatus;
