import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Modal, Progress } from 'antd';
import { find, includes, sortBy } from 'lodash';
import shortid from 'shortid';
import useFirebase from 'vendor/Firebase';
import {
  bodyDataAtom,
  neckDataAtom,
} from 'shared/state/pricingState';
import { bodyTotal, discountPrice, fetchPricing, formatPrice, neckTotal, updatePricing } from 'shared/data';
import { FlexRow } from 'shared/containers/FlexContainer';
import { PART_PRICING_COLLECTION, PART_VIEWER_COLLECTION, partConfigTermsAtom, partConfigTermTypesAtom } from 'shared/state/partViewState';
import { currentCustomerAtom, customersAtom } from 'shared/state/customerState';
import { configToDescription, updateConfig } from 'shared/partParser/util';
import theme from 'shared/theme';
import { ICustomerPart } from 'shared/types/parts';
import { cleanMeta } from 'shared/text';
import { CustomerDownloadButton } from '../styledComponents';

interface IComponent {
  isCustomer: boolean;
}

const ExportModal = styled(Modal)`
  width: 352px !important;
  text-align: center;
  border-radius: 12px;
  
  & .ant-modal-header {
    border-bottom: none;
  }
  
  & .ant-modal-body { 
    display: none;
  }
  
  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    border-top: none;
  }
  
  & .ant-modal-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
  }
`;
const ExportButton = styled(Button)`
  width: ${(props: any) => (props.isCustomer ? '152px' : 'auto')};
  border-radius: 8px;
  flex-grow: ${(props: any) => (props.isCustomer ? 0 : 2)};
  background-color: ${theme.palette.success.hue};
  border-color: ${theme.palette.success.hue};
  color: ${theme.palette.neutral.white};
  &:hover {
    background-color: ${theme.palette.success.D100};
    border-color: ${theme.palette.success.D100};
    color: ${theme.palette.neutral.white};
  }
  &:focus {
    background-color: ${theme.palette.success.hue};
    border-color: ${theme.palette.success.hue};
    color: ${theme.palette.neutral.white};
  }
`;
const ExportModalButton = styled(Button)`
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  width: 50%;
  background-color: unset;
`;
/**
 * Button component that is responsible for exporting pricing data as a csv file.
 *   By virtue of the way excel4node works, this has been designed as a two-step button,
 *   where the first click prepares the data, the second click downloads.
 */
export default ({ isCustomer = false }: IComponent) => {
  const { firestore } = useFirebase();
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const bodyData = useRecoilValue(bodyDataAtom);
  const neckData = useRecoilValue(neckDataAtom);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const partPricingCollectionDbString = useRecoilValue(PART_PRICING_COLLECTION);
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const customers = useRecoilValue(customersAtom);
  const csvHeaders = ['Active', 'Part Number', 'Part Name', 'Price'];
  const adminHeaders = ['Product Category', 'GL Code', 'Volume', 'Parent', 'Rev?'];
  // @ts-ignore
  const [csvData, setCsvData] = useState<any>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [processedPercent, setProcessedPercent] = useState(0);

  const resetProgress = () => {
    setIsExporting(false);
    setProcessedPercent(0);
    setCsvData([]);
  };

  const fetchPartRecords = async (customerId: string | null = null) => {
    let partQuery = firestore.collection(partViewerDbString);
    if (customerId) partQuery = partQuery.where('customer', '==', customerId);
    const partDocs = await partQuery.get();
    const parts = partDocs.docs.map((d) => d.data() as ICustomerPart);

    const pricingDocs = await firestore.collection(partPricingCollectionDbString).get();
    const partPricing = pricingDocs.docs.map((d: any) => ({ Sku: d.id, pricing: d.data() }));

    const BATCH_SIZE = 400;
    const batches = Array.from({ length: Math.ceil(parts.length / BATCH_SIZE) }, (_, i) => 
      parts.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE));
    
    const partRecords = [];
    for (let batchIndex = 0; batchIndex < batches.length; batchIndex += 1) {
      const batch = batches[batchIndex];
      
      // Process batch synchronously for speed
      const batchResults = batch.map((p) => {
        const customer = find(customers, (_c: any) => _c.id === p.customer);
        let rawPrice: any = p.price || 0;
        const pricing = find(partPricing, (_p: ICustomerPart) => _p.Sku === p.Sku) || { pricing: null};
        const updatedConfig = updateConfig(p.config || [], configTerms);
        const Description = configToDescription(updatedConfig);
        const record = updatePricing({ ...p, pricing: pricing.pricing }, [bodyData, neckData], includes(['GB', 'BB', 'CP'], p.type) ? 'body' : 'neck');

        if (record?.pricing) {
          const totalFunction = includes(Object.keys(record.pricing), 'neckWood') ? neckTotal : bodyTotal;
          rawPrice = totalFunction(record.pricing);
        }
        const customerDiscount = includes(['GB', 'BB'], p.type) 
          ? parseInt((customer?.bodyDiscount || 0), 10) 
          : parseInt((customer?.neckDiscount || 0), 10);

        const price = Math.round(discountPrice({ ...rawPrice, customerDiscount, type: p.type }, 100, true) as number);
        const parent = find(parts, (_p: ICustomerPart) => _p.Sku === p.parent);

        return { ...p, config: updatedConfig, pricing: pricing?.pricing || null, Description, parent: parent?.Sku || null, price };
      });
      
      partRecords.push(...batchResults);
      
      const percent = Math.round((((batchIndex + 1) * BATCH_SIZE) / parts.length) * 100);
      setProcessedPercent(Math.min(percent, 100));
      
      // Small delay between batches to allow UI updates
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 10));
    }

    return sortBy(partRecords, (p: ICustomerPart) => p.Sku);
  };

  const onExport = async () => {
    setModalOpen(false);
    setIsExporting(true);
    console.log('Starting export...');

    try {
      const priceRecords = await fetchPartRecords(null);
      console.log('Export complete');
      const newRecords = priceRecords.map((price: any) => ([
        price.active ? 'Y' : 'N',
        price.Sku,
        cleanMeta(configToDescription(price.config) || ''),
        formatPrice(Math.round(price.price)),
        'FINISHGOOD',
        '15900',
        price.volume || '',
        `${price.parent || ''}${price.parent ? '.' : ''}${price.Sku}`,
      ]));
      setCsvData([[...csvHeaders, ...adminHeaders], ...newRecords]);
    } catch (error) {
      console.error('Export failed:', error);
      resetProgress();
    }
  };
  
  const onCustomerExport = async () => {
    setModalOpen(false);
    setIsExporting(true);
    console.log('Starting customer export...');
    
    try {
      const priceRecords = await fetchPartRecords(currentCustomer.DisplayName);
      console.log('Customer export complete');
      const newRecords = sortBy(priceRecords, (p: any) => p.Sku).map((price: any) => {
        const defaultData = [
          price.active ? 'Y' : 'N',
          price.Sku,
          cleanMeta(configToDescription(price.config) || ''),
          formatPrice(Math.round(price.price)),
        ];
        if (!isCustomer) {
          defaultData.push('FINISHGOOD');
          defaultData.push('15900');
          defaultData.push(price.volume || '');
          defaultData.push(`${price.parent || ''}${price.parent ? '.' : ''}${price.Sku}`);
          defaultData.push(price.revisionNeeded ? 'Y' : 'N');
        }
        return defaultData;
      });
      
      let headers = csvHeaders;
      if (!isCustomer) headers = [...csvHeaders, ...adminHeaders];
      setCsvData([headers, ...newRecords]);
    } catch (error) {
      console.error('Customer export failed:', error);
      resetProgress();
    }
  };

  const onCancel = (e: any) => {
    setModalOpen(false);
  };

  const onExportClick = (e: any) => {
    if (!isCustomer) setModalOpen(true);
    else onCustomerExport();
  };

  useEffect(() => {
    setIsExporting(false);
    setCsvData([]);
  }, [currentCustomer]);

  return (
    <>
      <ExportModal
        open={modalOpen}
        closeIcon={<span />}
        title="Export which pricing data?"
        onCancel={onCancel}
        footer={[
          <ExportModalButton key="customer" type="primary" onClick={onCustomerExport}>
            {currentCustomer.shortName}
          </ExportModalButton>,
          <ExportModalButton
            key="all"
            type="default"
            onClick={onExport}
          >
            All Customers
          </ExportModalButton>,
        ]}
      />
      {csvData.length > 0 ? (
        <CustomerDownloadButton
          key={shortid.generate()}
          filename={`Wildwood Pricing Export ${new Date().toLocaleString()}.csv`}
          data={csvData}
          onClick={() => {
            setIsExporting(false);
            setProcessedPercent(0);
            setTimeout(() => {
              setCsvData([]);
            }, 1000);
          }}
        >
          Download Pricing
        </CustomerDownloadButton>
      ) : (
        <>
          {isExporting ? (
            <FlexRow style={{ width: 88, height: 64, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {processedPercent > 0 ? (
                <Progress type="circle" percent={processedPercent} width={64} style={{ marginLeft: 24 }} />
              ) : (
                <></>
              )}
            </FlexRow>
          ) : (
            <ExportButton isCustomer={isCustomer} onClick={onExportClick}>
              Export
            </ExportButton>
          )}
        </>
      )}
    </>
  );
};
