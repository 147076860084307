import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import {
  find, findIndex, first, includes, noop, sortBy, uniq,
} from 'lodash';
import { Divider, Modal, Input } from 'antd';
import {
  partEditModeAtom, customerPartAtom,
} from 'shared/state/pricingState';
import { DetailRow, PartDetailColumn as ComponentWrapper } from 'shared/styledComponents/containers';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import { PART_BOM_COLLECTION, PART_VIEWER_COLLECTION, partBomItemsAtom, partConfigTermsAtom, partConfigTermTypesAtom } from 'shared/state/partViewState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import useFirebase from 'vendor/Firebase';
import { currentCustomerAtom } from 'shared/state/customerState';
import { bodyTotal, neckTotal } from 'shared/data';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import HelpButton from 'shared/components/Utility/HelpButton';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import { configToDescription, descriptionToConfig, resolveParentSku } from 'shared/partParser/util';
import { updateRevisionNeeded } from 'shared/data/pricing';
import { findParent } from 'shared/partParser/partComparator';
import Loader from 'shared/components/Utility/Loader';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { attributeNote } from 'shared/text';
import { userSettingsAtom } from 'shared/state/siteState';
import { addHistoryEntry } from 'shared/data/history';
import { IPartHistoryEntry, PDRequirement } from 'shared/types/parts';
import DetailDropdownWithCallback from 'shared/components/Input/DetailDropdownWithCallback';
import DetailActiveSelectorWithCallback from 'shared/components/Input/DetailActiveSelectorWithCallback';
import { ORDERS_DB_COLLECTION, ORDER_ITEMS_DB_COLLECTION } from 'shared/state/orderState';
import { CustomerMenu } from './index';
import PartBomList from './PartBom/PartBomList';
import CopyDescriptionButton from '../../Orders/Components/SalesOrderRecord/Buttons/CopyDescriptionButton';
import QuickViewButton from './QuickView/Buttons/QuickViewButton';
import DensityCalcButton from './Buttons/DensityCalcButton';
import PartParentDropdown from './PartDetailFields/PartParentDropdown';
import DuplicatePartModal from './DuplicatePartModal';
import ActiveSelector from '../../Customer/Components/PriceListInputs/ActiveSelector';

const PartDetailRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 12px;
`;

const PartNotesRow = styled(FlexRow)`
  width: 100%;
  flex-grow: 2;
  justify-content: space-between;
`;

const PartNumberWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
    margin-right: 8px;
`;

const PartLineageWrapper = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`;

const InheritanceSelectorRow = styled(FlexRow)`
    justify-content: flex-start;
    gap: 12px;
`;

const LoaderWrapper = styled(FlexRow)`
    justify-content: flex-start;
    margin-top: 14px;
    gap: 8px;
`;

const PartParentWrapper = styled(FlexRow)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
`;
const ParentDescriptionInput = styled(DetailInputWithCallback)`
    margin-top: 2px;
`;

interface IComponent {
  partType: 'body'|'neck';
  partId: string;
  copy: boolean;
  customerParts: any[];
}
const PartDetailColumn = ({
  partType, partId, copy, customerParts = [],
}: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  // @ts-ignore
  const [newPartState, setNewPartState] = useRecoilState(customerPartAtom);
  const customer = useRecoilValue(currentCustomerAtom);
  const editMode = useRecoilValue(partEditModeAtom);
  const partBomCollection = useRecoilValue(PART_BOM_COLLECTION);
  const partViewerDataString = useRecoilValue(PART_VIEWER_COLLECTION); 
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION) as string;
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION) as string;
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const configTermTypes = useRecoilValue(partConfigTermTypesAtom);
  const setBomItems = useSetRecoilState(partBomItemsAtom);
  const userSettings = useRecoilValue(userSettingsAtom);

  const totalFunction = partType.match(/body/i) ? bodyTotal : neckTotal;
  const discount = partType.match(/body/i) ? (customer?.bodyDiscount || 0) : (customer?.neckDiscount || 0);
  const [price, setPrice] = useState<number>(0);
  const [bypassConflictCheck, setBypassConfictCheck] = useState<boolean>(false);
  const [_customerParts, setCustomerParts] = useState<any[]>(customerParts);
  const [showDuplicatePartModal, setShowDuplicatePartModal] = useState<boolean>(false);
  const [duplicatePart, setDuplicatePart] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [onLoadingParts, setOnLoadingParts] = useState<boolean>(false);
  const [partDescription, setPartDescription] = useState<string>(configToDescription(newPartState?.config || []));
  const [showRevisionModal, setShowRevisionModal] = useState<boolean>(false);
  const [revisionNote, setRevisionNote] = useState<string>('');
  const [pendingRevisionState, setPendingRevisionState] = useState<boolean>(false);

  const pdRequirementOptions = [
    { label: 'New Part', value: 'New' },
    { label: 'Variant Part', value: 'Variant' },
    { label: 'Revision Needed', value: 'Revision' },
    { label: 'Other Development', value: 'Other' }
  ];

  const [showDevNotesModal, setShowDevNotesModal] = useState(false);
  const [pendingPdRequirement, setPendingPdRequirement] = useState<PDRequirement>(null);

  const handlePdRequirementChange = (value: PDRequirement | null) => {
    if (!value) {
      // If clearing the requirement
      setNewPartState({
        ...newPartState,
        pdRequirement: null,
        pdCompletedDate: null,
        pdNotes: null
      });
      return;
    }

    setPendingPdRequirement(value);
    setShowDevNotesModal(true);
  };

  const handleDevNotesConfirm = async (notes: string) => {
    setNewPartState({
      ...newPartState,
      pdRequirement: pendingPdRequirement,
      pdCompletedDate: null,
      pdNotes: attributeNote(currentUser, notes)
    });

    // Add history entry
    const historyEntry: IPartHistoryEntry = {
      timestamp: firebase.firestore.Timestamp.now(),
      user: currentUser.email,
      action: 'development_started',
      notes
    };

    await addHistoryEntry(firestore, newPartState.Sku, historyEntry);
    setShowDevNotesModal(false);
  };

  const onCloseDuplicatePartModal = () => {
    setShowDuplicatePartModal(false);
  };

  const onChangeDescription = (update: boolean = false) => async (value: string) => {
    if (update) {
      const config = descriptionToConfig(value
        .replace('COPY_', '') // just in case this part is copied, and the user forgot to remove the COPY_ bit.
        .replace(/"/g, ''), // no quotes!!!
      configTerms,
      configTermTypes);
      const sanitizedDescription = configToDescription(config) as string;
      const updatedState = { ...newPartState, Description: sanitizedDescription, config };

      // Update the current part
      setPartDescription(sanitizedDescription);
      setNewPartState(updatedState);

      // Auto-select any labor or part options based on the description

      if (update) {
        firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
          if (doc.exists) {
            firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ Description: sanitizedDescription });
          }
        });
      }

      const conflicts = _customerParts
        .filter((p: any) => p && p.Sku !== newPartState.Sku && configToDescription(p.config) === sanitizedDescription);
      const conflict = first(sortBy(conflicts, (p) => parseInt(p.Sku.split('_')[1], 10)));

      if (conflict && !bypassConflictCheck) {
        setDuplicatePart(conflict);
        setShowDuplicatePartModal(true);
      }
      const parentCandidates = findParent({ ...newPartState, config }, _customerParts)?.filter((p) => p.Sku !== newPartState.Sku && parseInt(p.Sku.split('_')[1], 10) < parseInt(newPartState.Sku.split('_')[1], 10));
      const parent = parentCandidates ? first(parentCandidates) : null;
      const updatedRecord = { ...updatedState, parent: parent || undefined };
      setNewPartState(updatedRecord);
      if (update) {
        firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
          if (doc.exists) {
            firestore.collection(partViewerDataString).doc(newPartState.Sku).update({
              config,
              // @ts-ignore
              parent: updatedRecord.parent?.Sku || null,
            });
          }
        });
      }
    } else {
      setPartDescription(value);
    }
  };

  const onChangeSku = (newSku: string) => {
    setNewPartState({ ...newPartState, Sku: newSku });
  };

  const onChangeVolume = async (newVolume: string, update: boolean = false) => {
    setNewPartState({ ...newPartState, volume: newVolume });
    if (!update) return;

    // check to see if the document exists yet. In the case of creating a new part, it will not and we can just bail
    const partDoc = await firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get();
    if (!partDoc.exists) return;

    const updateSkus = [newPartState.Sku];
    /*
    If the document has a parent, we need to update that volume as well, since the parent volume will always override the child volume.
     */
    if (newPartState.parent) updateSkus.push(newPartState.parent.Sku);
    /*
    If the document has children, we need to update each child with the same volume
     */
    if (newPartState.childParts) updateSkus.push(...newPartState.childParts);

    // Update any and all Skus with the new volume
    await Promise.all(updateSkus.map((p: string) => firestore.collection(partViewerDataString).doc(p).get().then((pD) => {
      if (pD.exists) firestore.collection(partViewerDataString).doc(p).update({ volume: newVolume });
    })));
  };

  const onChangeNotes = (update: boolean = false) => (value: string) => {
    setNewPartState({ ...newPartState, notes: value });
    if (update) {
      firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
        if (doc.exists) firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ notes: value });
      });
    }
  };

  const onChangeShowNotesOnOrder = (value: boolean) => {
    setNewPartState({ ...newPartState, showNotesOnOrder: value });
    // firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
    //   if (doc.exists) firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ showNotesOnOrder: value });
    // });
  };

  const onChangeOneTimeNote = (update: boolean = false) => (value: string) => {
    setNewPartState({ ...newPartState, oneTimeNote: value });
    if (update) {
      firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
        if (doc.exists) firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ oneTimeNote: value });
      });
    }
  };

  const onChangeActive = async (isActive: boolean) => {
    setNewPartState({ ...newPartState, active: isActive });
    if (!isActive && newPartState.childParts) {
      // if we are deactivating a part and it has children, transfer the inheritance to its first child
      const [newParent, ...children] = newPartState.childParts;
      await firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ childParts: null });
      if (newParent) {
        await firestore.collection(partViewerDataString).doc(newParent).update({
          childParts: children,
          parent: null,
        });
        await Promise.all(children.map((sku: string) => firestore.collection(partViewerDataString).doc(sku).update({
          parent: newParent,
        })));
      }
    }
  };

  const onChangeInheritance = async (inherits: boolean) => {
    const parent = inherits ? '' : null;
    const update = { ...newPartState, parent } as any;
    const partDoc = await firestore.collection(partViewerDataString).doc(newPartState.Sku).get();
    if (partDoc.exists) await firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ parent });
    if (inherits && !_customerParts.length) {
      setIsLoading(true);
      const customerPartDocs = await firestore.collection(partViewerDataString)
        .where('customer', '==', newPartState.customer)
        .where('type', '==', configToDescription(newPartState.config).replace('COPY_', '').substring(0, 2))
        .get();
      setIsLoading(false);
      const parts = customerPartDocs.docs.map((d) => d.data());
      setCustomerParts(parts);
    }
    if (!inherits && newPartState.parent) {
      const parentRecord = newPartState.parent;
      if (!parentRecord) return;

      const childParts = (parentRecord.childParts || []).filter((s) => s !== newPartState.Sku);
      firestore.collection(partViewerDataString).doc(parentRecord.Sku).update({ childParts }).then(() => {
        const newParent = { ...parentRecord, childParts };
        const newParentIndex = findIndex(_customerParts, (p) => p.Sku === parentRecord.Sku);
        const newParts = [..._customerParts];
        newParts[newParentIndex] = newParent;
        setCustomerParts(newParts);
      });
    }
    setNewPartState(update);
  };

  const onChangeParentSku = (parentSku: string) => {
    const parent = find(_customerParts, (p) => p.Sku === parentSku);
    if (!parent) return;
    if (window.location.href.match(/edit/i)) {
      firestore.collection(partViewerDataString).doc(newPartState.Sku).update({
        parent: parent.Sku,
      }).then(() => {
        const childParts = uniq([...(parent.childParts || []), newPartState.Sku]);
        firestore.collection(partViewerDataString).doc(parent.Sku).update({ childParts }).then(() => {
          setNewPartState({ ...newPartState, parent });
        });
      });
    } else {
      setNewPartState({ ...newPartState, parent });
    }
  };

  const onChangeLoad = (type: 'cnc' | 'finishing') => (value: string) => {
    if (type === 'cnc') {
      setNewPartState({ ...newPartState, cncLoad: value });
    } else {
      setNewPartState({ ...newPartState, finishingLoad: value });
    }
  };

  const [selectedRequirement, setSelectedRequirement] = useState<PDRequirement>(null);

  const isNoteRequired = selectedRequirement === 'Revision' || selectedRequirement === 'Other';
  const isNoteOptional = selectedRequirement === 'Variant' || selectedRequirement === 'New';
  const isFormValid = selectedRequirement && (!isNoteRequired || (isNoteRequired && revisionNote.trim().length > 0));

  const onUpdateDevelopmentStatus = (status: PDRequirement) => {
    const statusText = find(pdRequirementOptions, (o) => o.label === status)?.value || status;
    setNewPartState({ ...newPartState, pdRequirement: statusText as PDRequirement });
    setSelectedRequirement(statusText as PDRequirement);
  };

  const onChangeRevisionNeeded = async (value: boolean) => {
    if (value) {
      setShowRevisionModal(true);
    } else {
      // Clear development status
      setNewPartState({
        ...newPartState,
        pdRequirement: null,
        pdCompletedDate: null,
        pdNotes: null
      });
      await updateRevisionNeeded([newPartState.Sku], firestore, ordersDbString, orderItemsDbString, newPartState.customer, null, null);
    }
  };

  const handleRevisionModalOk = async () => {
    setShowRevisionModal(false);

    const notes = isNoteRequired ? revisionNote : `${selectedRequirement} part`;
    setNewPartState({
      ...newPartState,
      pdRequirement: selectedRequirement,
      pdCompletedDate: null,
      pdNotes: attributeNote(currentUser, notes),
    });

    // Add history entry
    const historyEntry: IPartHistoryEntry = {
      timestamp: firebase.firestore.Timestamp.now(),
      user: currentUser.email,
      action: 'development_started',
      notes,
    };

    const requirementNote = notes?.length > 0 ? attributeNote(currentUser, notes) : null;

    await addHistoryEntry(firestore, newPartState.Sku, historyEntry);
    await firestore.collection(partViewerDataString).doc(newPartState.Sku).update({
      pdRequirement: selectedRequirement,
      pdNotes: requirementNote,
    });

    // Update any open orders containing this part
    await updateRevisionNeeded(
      [newPartState.Sku],
      firestore,
      ordersDbString,
      orderItemsDbString,
      newPartState.customer,
      selectedRequirement,
      requirementNote,
    );
    
    // if we are tagging a part for revision, we need to flag all children and parent as well
    if (newPartState.childParts && selectedRequirement === 'Revision') {
      await Promise.all(newPartState.childParts.map((p) => 
        firestore.collection(partViewerDataString).doc(p).update({ 
          pdRequirement: selectedRequirement,
          pdNotes: attributeNote(currentUser, notes),
        })));
      await updateRevisionNeeded(
        newPartState.childParts, 
        firestore,
        ordersDbString,
        orderItemsDbString,
        newPartState.customer,
        selectedRequirement,
        requirementNote
      );
    } else if (newPartState.parent && selectedRequirement === 'Revision') {
      const parentSku = resolveParentSku(newPartState.parent);
      const childrenDocs = await firestore.collection(partViewerDataString)
        .where('parent', '==', parentSku)
        .get();
      const childSkus = childrenDocs.docs
        .map((d) => d.data().Sku)
        .filter((s) => s !== newPartState.Sku);
      const updateSkus = [...childSkus, parentSku];

      await updateRevisionNeeded(
        updateSkus, 
        firestore, 
        ordersDbString, 
        orderItemsDbString, 
        newPartState.customer, 
        selectedRequirement,
        requirementNote
      );

      await Promise.all([
        ...childSkus.map((p) => 
          firestore.collection(partViewerDataString).doc(p).update({ 
            pdRequirement: selectedRequirement,
            pdNotes: attributeNote(currentUser, notes),
          })),
        firestore.collection(partViewerDataString).doc(parentSku).update({ 
          pdRequirement: selectedRequirement,
          pdNotes: attributeNote(currentUser, notes),
          pdCompletedDate: null 
        })
      ]);
    }

    // Reset modal state
    setRevisionNote('');
    setSelectedRequirement(null);
  };

  const handleRevisionModalCancel = () => {
    setShowRevisionModal(false);
    setRevisionNote('');
    setSelectedRequirement(null);
  };

  useEffect(() => {
    firestore.collection(partBomCollection).doc(partId || newPartState.Sku).get().then((doc) => {
      if (!doc.exists) return;
      const bomData = doc.data() || { bom: [] };
      setBomItems(bomData.bom);
      const refreshButton = document.getElementById('refresh-jb-items-button');
      if (refreshButton) refreshButton.click();
    });
  }, [newPartState.Sku]);

  useEffect(() => {
    const total = totalFunction(newPartState) as any;
    const newPrice = Math.round(total.price * (1 + (discount / 100)) + total.discount);
    setPrice(newPrice);
  }, [newPartState]);

  useEffect(() => { 
    // setPartDescription(orderTermsByRank(newPartState.config, configTerms).join('_'));
  }, [newPartState.Sku]);

  useEffect(() => {
    if (customerParts.filter((p) => p).length === 0) {
      firestore.collection(partViewerDataString).where('customer', '==', newPartState.Sku.split('_')[0]).where('type', '==', newPartState.type).get()
        .then((docs) => {
          setCustomerParts(docs.docs.map((d) => d.data()));
        });
    } else {
      setCustomerParts(customerParts);
    }
  }, [customerParts, newPartState.customerId, newPartState.Sku]);

  return (
    <>
      {duplicatePart !== null && (
        <DuplicatePartModal
          showModal={showDuplicatePartModal}
          currentPart={newPartState}
          duplicatedPart={duplicatePart}
          closeCallback={onCloseDuplicatePartModal}
        />
      )}
       <Modal
         title="Development Requirements"
         open={showRevisionModal}
         onOk={handleRevisionModalOk}
         onCancel={handleRevisionModalCancel}
         okButtonProps={{ disabled: !isFormValid }}
       >
         <FlexColumn style={{ gap: 16 }}>
           <DetailDropdownWithCallback
             id="pd-requirement-type"
             label="Development Type"
             listPrompt="Select development type..."
             optionsList={pdRequirementOptions}
             selectedOption={selectedRequirement || undefined}
             changeCallback={(value) => onUpdateDevelopmentStatus(value as PDRequirement)}
             width="100%"
           />
            <DetailTextAreaWithCallback
              id="pd-notes-input"
              label="Development Notes"
              placeholder={`${isNoteOptional ? 'Optional' : 'Required'} details about the needed development...`}
              value={revisionNote}
              changeCallback={(value) => setRevisionNote(value)}
              blurCallback={(value) => setRevisionNote(value)}
              height={100}
              required={isNoteRequired}
            />
         </FlexColumn>
       </Modal>
      <ComponentWrapper key="part-details-component-wrapper">
        <PartDetailColumnHeader key="part-details-column-header">Part Details</PartDetailColumnHeader>
        <PartDetailRow>
          <CustomerMenu partType={partType} partId={newPartState.Sku || partId} copy={copy} />
          <PartNumberWrapper>
            <DetailInputWithCallback
              id="part-details-sku-input"
              label="Part Number"
              placeholder="e.g., NASHG_00001"
              value={newPartState.Sku}
              callback={onChangeSku}
              extend={false}
              isLabel={false}
              disabled={!editMode}
            />
            {partId && (
              <QuickViewButton partId={partId} qboId={newPartState.Id} />
            )}
          </PartNumberWrapper>
          <DetailActiveSelectorWithCallback
            id="active-selector"
            initialState={newPartState.active}
            callback={onChangeActive}
            disabled={!editMode}
            componentLabel="Status"
            checkedLabel="ACTIVE"
            uncheckedLabel="INACTIVE"
            componentMargin="-16px 0 0 0"
          />
          <DetailActiveSelectorWithCallback
            id="revision-selector"
            initialState={!!newPartState.pdRequirement}
            bypassChangeHandler
            callback={onChangeRevisionNeeded}
            disabled={!editMode}
            componentLabel="Add to Dev queue?"
            checkedLabel="YES"
            uncheckedLabel="NO"
            componentMargin="-16px 0 0 12px"
            LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="If this part needs any revision before next run, toggle this to YES -- a flag will appear on the order. Will be checked by default for new parts." />}
            LabelActiveClass="active-selector-active-label"
            async={onLoadingParts}
          />
        </PartDetailRow>
        <DetailTextAreaWithCallback
          id="part-details-description-text-area"
          label="Description"
          LabelExtra={<CopyDescriptionButton description={partDescription} />}
          placeholder="e.g., GB_Tele_Vin_S/S_Ash XLite_4Lb 0"
          value={partDescription}
          changeCallback={onChangeDescription(false)}
          blurCallback={onChangeDescription(true)}
          disabled={!editMode}
          height={40}
        />
        <DetailTextAreaWithCallback
          id="part-details-notes-text-area"
          label="Part notes"
          labelStyle={{ width: 88 }}
          LabelExtra={(
            <PartNotesRow>
            <HelpButton
              style={{ marginBottom: 2 }}
              helpMessage="This is a place to add internal notes about a part. Notes will not show up on sales orders."
            />
            <DetailActiveSelectorWithCallback
              id="part-details-show-notes-on-order-selector"
              componentLabel="Show notes on order?"
              initialState={newPartState.showNotesOnOrder || false}
              callback={onChangeShowNotesOnOrder}
              checkedLabel="YES"
              uncheckedLabel="NO"
              disabled={!editMode}
              labelMode="inline"
              LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Toggle to YES to show this note any time this part is added to an order." />}
            />
            </PartNotesRow>
          )}
          placeholder="Add any internal notes here. Notes will not show up on sales orders."
          value={newPartState.notes}
          blurCallback={onChangeNotes(true)}
          changeCallback={onChangeNotes(false)}
          disabled={!editMode}
          height={40}
        />
        <DetailTextAreaWithCallback
          id="part-details-one-time-note-text-area"
          label="OneTime™ note"
          LabelExtra={(
            <HelpButton
              style={{ marginBottom: 2 }}
              helpMessage="Add a one-time note that will convey information for this part on the next order to which it is added."
            />
          )}
          placeholder="Add a note for the next order here. This note will self-destruct once this part is added to a new order."
          value={newPartState.oneTimeNote}
          blurCallback={onChangeOneTimeNote(true)}
          changeCallback={onChangeOneTimeNote(false)}
          disabled={!editMode}
          height={40}
        />
        {partType === 'body' && (
          <PartDetailRow>
            <DetailInputWithCallback
              id="part-details-body-volume-input"
              label="Body Volume"
              placeholder="0.00"
              value={newPartState.volume}
              callback={onChangeVolume}
              extend={false}
              isLabel={false}
              disabled={!editMode}
            />
            <DensityCalcButton />
          </PartDetailRow>
        )}
        {partType === 'neck' && (
          <DetailRow>
            {userSettings?.boolean?.showCNCLoad && (
              <ActiveSelector
                key="part-details-cnc-impact-selector"
                activeType={newPartState.cncLoad || 'baby'}
                callback={onChangeLoad('cnc')}
                allValue="Baby"
                activeValue="Mama"
                inactiveValue="Papa"
                label="CNC Impact"
                showLabel
                margin="0 0 18px 0"
                disabled={!editMode}
              />
            )}
            {userSettings?.boolean?.showFinishingLoad && (
            <ActiveSelector
              key="part-details-finishing-impact-selector"
              activeType={newPartState.finishingLoad || 'baby'}
              callback={onChangeLoad('finishing')}
              allValue="Baby"
              activeValue="Mama"
              inactiveValue="Papa"
              label="Finishing Impact"
              showLabel
              margin="0 0 18px 0"
              disabled={!editMode}
            />
            )}
          </DetailRow>
        )}
        <PartLineageWrapper>
          <Divider />
          <InheritanceSelectorRow>
            <DetailActiveSelectorWithCallback
              id={partId}
              initialState={!includes([undefined, null], newPartState.parent)}
              callback={onChangeInheritance}
              disabled={!editMode}
              componentLabel="Child Part?"
              checkedLabel="Yes"
              uncheckedLabel="No"
              LabelExtra={<HelpButton helpMessage="Select 'Yes' if this part is on, or being added to, a spec sheet that has another part already in existence." style={{ marginBottom: 4 }} />}
            />
            {isLoading && (
            <LoaderWrapper>
              <Loader label="Loading parts..." radius={24} borderSize={8} />
            </LoaderWrapper>
            )}
          </InheritanceSelectorRow>
          {!includes([undefined, null], newPartState.parent) && (
          <PartParentWrapper>
            <PartParentDropdown
              disabled={!editMode}
              partType={partDescription?.substring(0, 2) || ''}
              customerParts={_customerParts}
              partState={newPartState}
              callback={onChangeParentSku}
            />
            <DetailInputWithCallback
              width="400px"
              key="inventory-part-parent-description"
              label="Description"
              placeholder=""
              value={configToDescription(newPartState.parent?.config || [])}
              callback={noop}
              isLabel
              extend
              disabled
            />
          </PartParentWrapper>
          )}
          <Divider />
        </PartLineageWrapper>
        <PartBomList
          open
          partType={partType}
          partId={partId || newPartState.Sku}
          partPrice={price}
          partDescription={configToDescription(newPartState.config || [])}
        />
      </ComponentWrapper>
    </>
  );
};

export default PartDetailColumn;
