import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import { ButtonIsland } from 'shared/styledComponents/containers';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import ExportPricingButton from '../Buttons/ExportPricingButton';
import NewItemButton from '../PriceList/PricedItem/Buttons/NewItemButton';
import Uploader from '../CustomerPartUploader';
import CustomerDiscount from '../CustomerDiscount';
import PercentLockButton from './PercentLockButton';
import { CustomerDiscountWrapper } from '../styledComponents';
import DetailNumberInputWithCallback from '../../../../shared/components/Input/DetailInputWithCallback';

/**
 * Component that is responsible for creating new parts, exporting pricing information, and filtering existing parts.
 */

const ComponentWrapper = styled(FlexRow)`
`;
const DiscountInputWrapper = styled(FlexRow)`
    position: relative;
    bottom: 12px;
    gap: 16px;
    padding: 0 16px;
`;
interface IComponent {
  percentChangeCallback: (bodyDiscount: number, neckDiscount: number) => void;
  lockChangeCallback: (locked: boolean) => void;
  locked: boolean;
  bodyDiscount: number;
  neckDiscount: number;
}
const AdminInputs = ({
  percentChangeCallback, lockChangeCallback, bodyDiscount, neckDiscount, locked,
}: IComponent) => {
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const [_locked, setLocked] = useState<boolean>(locked);
  const [_bodyDiscount, setBodyDiscount] = useState<number>(bodyDiscount);
  const [_neckDiscount, setNeckDiscount] = useState<number>(neckDiscount);

  const onDiscountBlur = (type: 'body'|'neck') => (value: string, didBlur: boolean) => {
    const discount = type.match(/body/i)
      ? { bodyDiscount: parseInt(value, 10), neckDiscount }
      : { bodyDiscount, neckDiscount: parseInt(value, 10) };

    if (didBlur) percentChangeCallback(discount.bodyDiscount, discount.neckDiscount);
  };

  useEffect(() => {
    setLocked(locked);
  }, [locked]);

  useEffect(() => {
    setBodyDiscount(bodyDiscount);
  }, [bodyDiscount]);

  useEffect(() => {
    setNeckDiscount(neckDiscount);
  }, [neckDiscount]);

  return (
    <ComponentWrapper>
      <ButtonIsland expand padding="8px 16px">
        <NewItemButton />
        <ExportPricingButton isCustomer={false} />
        <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
          <Uploader />
        </ScopedComponent>
        <PercentLockButton changeCallback={lockChangeCallback} locked />
      </ButtonIsland>
      <DiscountInputWrapper>
        <DetailNumberInputWithCallback
          key="bodyDiscount-input"
          label="Body %"
          placeholder="0"
          value={_bodyDiscount}
          callback={onDiscountBlur('body')}
          disabled={_locked}
          noMargin
          width="50px"
        />
        <DetailNumberInputWithCallback
          key="neckDiscount-input"
          label="Neck %"
          placeholder="0"
          value={_neckDiscount}
          callback={onDiscountBlur('neck')}
          disabled={_locked}
          noMargin
          width="50px"
        />
      </DiscountInputWrapper>
    </ComponentWrapper>
  );
};

export default AdminInputs;
