import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PriceList from 'pages/Customer/Components/PriceList';
import ItemListFilter from 'shared/components/Input/ItemListFilter';
import { copiedBomPartNumberAtom, PART_VIEWER_COLLECTION, showBomCopyDrawerAtom } from 'shared/state/partViewState';
import {
  currentCustomerPrices,
  priceSearchQueryAtom,
} from 'shared/state/pricingState';
import { currentCustomerAtom } from 'shared/state/customerState';
import useFirebase from 'vendor/Firebase';
import { discountPrice } from 'shared/data';
import { listFilterQuery } from 'shared/util';
import { configToDescription } from 'shared/partParser/util';
import { cleanMeta } from 'shared/text';

const CopyDrawer = styled(Drawer)`
  height: 100vh;
  z-index: 900000000;
`;

interface IComponent {
  callback: (partNumber: string) => void;
  partDescription: string;
  searchQuery: string;
}
const BomCopyDrawer = ({ callback, partDescription, searchQuery }: IComponent) => {
  const { firestore } = useFirebase();
  const partCollectionDBString = useRecoilValue(PART_VIEWER_COLLECTION);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const [showBomCopyDrawer, setShowBomCopyDrawer] = useRecoilState(showBomCopyDrawerAtom);
  const copiedBomPartNumber = useRecoilValue(copiedBomPartNumberAtom);
  const [pricing, setPricing] = useRecoilState(currentCustomerPrices);
  const [_searchQuery, setSearchQuery] = useState<string[]>(listFilterQuery(searchQuery));
  const [renderItems, setRenderItems] = useState<any>([]);
  const setPricingSearchQuery = useSetRecoilState(priceSearchQueryAtom);

  const updatePageData = (activeStatus: string, priceType: string, _query: string[] = _searchQuery): void => {
    // const query = listFilterQuery(_query);
    const discountedPricing = pricing.map((p: any) => ({
      ...p,
      Description: configToDescription(p.config),
      discountedPrice: discountPrice(p, currentCustomer),
    }));
    setRenderItems(_.sortBy(discountedPricing, (p: any) => p.Sku)
      .filter((p: any) => {
        if (activeStatus === 'active') return p.active;
        if (activeStatus === 'inactive') return !p.active;
        return true;
      })
      .filter((p: any) => {
        if (priceType === 'priced') return p.pricing;
        if (priceType === 'unpriced') return !p.pricing;
        return true;
      })
      .filter((p: any) => {
        // const query = listFilterQuery(queryString);
        const matches = _query.map((t: string) => `${configToDescription(p.config)}${p.Sku}`.match(new RegExp(t.trim(), 'i')));
        return _.every(matches, Boolean);
      }));
  };
  useEffect(() => {
    firestore.collection(partCollectionDBString).where('customer', '==', currentCustomer.id).get().then((snap) => {
      const prices: any[] = [];
      snap.forEach((doc) => {
        prices.push(doc.data());
      });
      // @ts-ignore
      setPricing(prices);
    });
  }, []);

  useEffect(() => {
    setShowBomCopyDrawer(false);
    if (copiedBomPartNumber.match(/[A-Z]{5}_[0-9]{5}/i)) {
      callback(copiedBomPartNumber);
    }
  }, [copiedBomPartNumber]);

  useEffect(() => {
    const _query = listFilterQuery(searchQuery);
    setSearchQuery(_query);
  }, [searchQuery]);

  const onClose = (e: any) => {
    setShowBomCopyDrawer(false);
    setPricingSearchQuery('');
    sessionStorage.removeItem('pricing.query.string');
  };

  const onPartFilterChange = (value: string) => {
    const _query = listFilterQuery(value);
    sessionStorage.setItem('pricing.query.string', value);
    setSearchQuery(_query);
    updatePageData('any', 'any', _query);
  };

  return (
    <CopyDrawer
      title={`Copy BOM to Part: ${partDescription}`}
      open={showBomCopyDrawer}
      closable
      onClose={onClose}
      width="100vw"
      height="100vh"
      placement="top"
    >
      <ItemListFilter
        id="part-filter"
        prompt="Filter by Part Number"
        initialValue={_searchQuery.map((s) => cleanMeta(s)).join(', ')}
        callback={onPartFilterChange}
        callbackOnChange
      />
      <PriceList renderItems={renderItems} />
    </CopyDrawer>
  );
};

export default BomCopyDrawer;
