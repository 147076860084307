import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Button, Divider, Modal, Radio,
} from 'antd';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { FlexRow } from 'shared/containers/FlexContainer';

import { salesOrderEditedAtom } from 'shared/state/routingState';
import { RadioChangeEvent } from 'antd/lib/radio';
import { isProduction, resolveRealtimePath, useTestData } from 'shared/util';
import {
  AddRunnerColumn, RunnerHeaderRow, RunnerPageTitle, DeleteSalesOrderButton,
} from '../../ProductionSchedule/styledComponents';
import {
  DescInput,
  FieldWrapper,
  FieldRow,
  InputCaption,
  SOInput,
} from '../../ProductionSchedule/Components/styledComponents';
import { IArchetype } from '../../types';

const _ = require('lodash');
const shortid = require('shortid');
const qs = require('qs');

export default () => {
  const { database } = useFirebase();
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [instrumentType, setInstrumentType] = useState<string>('M');
  const [edited, setEdited] = useRecoilState(salesOrderEditedAtom);

  const urlParse = qs.parse(window.location.search.replace('?', ''));
  const woodType = urlParse.woodType;
  const partId = urlParse.partId;
  const partType = resolveRealtimePath(urlParse.partType);

  const handlers = {
    change: {
      partName: (e: any) => {
        if (!edited) setEdited(true);
        setName(e.target.value);
      },
      pricing: (e: any) => {
        if (!edited) setEdited(true);
        const archetypePrice = e.target.value.length ? parseFloat(e.target.value) : 0;
        setPrice(archetypePrice);
      },
      instrument: (e: RadioChangeEvent) => {
        if (!edited) setEdited(true);
        setInstrumentType(e.target.value);
      },
    },
    delete: (e: any) => {
      e.preventDefault();
      Modal.confirm({
        content: 'Are you sure you want to delete this part?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          database.ref(`/${resolveRealtimePath(partType)}/archetype/items`).once('value').then((snap) => {
            const data = snap.val() as IArchetype[];
            database.ref(`/${resolveRealtimePath(partType)}Test/archetype/items`).set(data.filter((i: IArchetype) => i.id !== partId)).then(() => {
              // if (!useTestData) {
              //   database.ref(`/${partType}Test/archetype/items`).set(data.filter((i: IArchetype) => i.id !== partId)).then(() => {
              //     window.location.href = '/pricing/edit';
              //   });
              // } else {
              window.location.href = '/pricing/edit';
              // }
            });
          });
        },
      });
    },
    update: (e: any) => {
      e.preventDefault();
      const record = {
        id: partId,
        price,
        type: name,
        instrument: instrumentType,
      };

      database.ref(`/${resolveRealtimePath(partType)}/archetype/items`).once('value').then((snap) => {
        const records = snap.val() as IArchetype[];
        const updateIndex = _.findIndex(records, (i: IArchetype) => i.id === partId);
        database.ref(`/${partType}/archetype/items/${updateIndex}`).set(record).then(() => {
          if (!useTestData) {
            database.ref(`/${partType}Test/archetype/items/${updateIndex}`).update({
              type: name, instrument: instrumentType,
            }).then(() => {
              window.location.href = '/pricing/edit';
            });
          } else {
            window.location.href = '/pricing/edit';
          }
        });
      });
    },
    // eslint-disable-next-line consistent-return
    submit: (e: any) => {
      e.preventDefault();
      if (partId) return handlers.update(e);

      let errorText = null;
      if (name.length === 0) {
        errorText = 'Please add a name for this archetype';
      }
      if (price === 0) {
        errorText = 'Please add a price for this archetype';
      }
      if (errorText) {
        return Modal.error({
          content: errorText,
          okText: 'OK',
        });
      }
      const record = {
        id: shortid.generate(),
        price,
        type: name,
        instrument: instrumentType,
      };
      database.ref(`/${resolveRealtimePath(partType)}/archetype/items`).once('value').then((snap) => {
        const records = snap.val() as IArchetype[];
        const updateIndex = records.length;
        database.ref(`/${resolveRealtimePath(partType)}/archetype/items/${updateIndex}`).set(record).then(() => {
          if (isProduction()) {
            database.ref(`/${partType}Test/archetype/items/${updateIndex}`).set(record).then(() => {
              window.location.href = '/pricing/edit';
            });
          } else {
            window.location.href = '/pricing/edit';
          }
        });
      });
    },
  };

  useEffect(() => {
    if (partId) {
      database.ref(`/${resolveRealtimePath(partType)}/archetype/items`).once('value').then((snap) => {
        const data = _.find(snap.val(), (i: IArchetype) => i.id === partId) as IArchetype;
        if (!data) return;

        setName(data.type);
        setPrice(data.price);
        setInstrumentType(data.instrument);
      });
    }
  }, []);

  return (
    <ConfigurationCol>
      <RunnerHeaderRow>
        <RunnerPageTitle>{`${partId ? 'Edit' : 'Add'} Part Material`}</RunnerPageTitle>
        <FlexRow style={{ flexGrow: 2, marginTop: 4, gap: 20 }} justify="flex-start">
          <Button type="primary" shape="round" onClick={handlers.submit}>{partId ? 'Save' : 'Create'}</Button>
          {!!partId
          && <DeleteSalesOrderButton type="primary" onClick={handlers.delete}>Delete</DeleteSalesOrderButton>}
        </FlexRow>
      </RunnerHeaderRow>
      <AddRunnerColumn>
        <Divider key={shortid.generate()} orientation="left">Archetype</Divider>
        <FieldRow>
          <FieldWrapper style={{ width: 456 }}>
            <InputCaption>Archetype name</InputCaption>
            <DescInput
              onChange={handlers.change.partName}
              defaultValue=""
              value={name}
              placeholder="e.g., 2Pc. Flat Bass Neck"
            />
          </FieldWrapper>
        </FieldRow>

        <FieldRow>
          <FieldWrapper>
            <InputCaption>Instrument Type</InputCaption>
            <Radio.Group value={instrumentType} buttonStyle="solid" onChange={handlers.change.instrument}>
              {urlParse.partType === 'body' && (
              <Radio.Button value="S">Small</Radio.Button>
              )}
              <Radio.Button value="M">Medium</Radio.Button>
              <Radio.Button value="L">Large</Radio.Button>
            </Radio.Group>
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Price</InputCaption>
            <SOInput
              onChange={handlers.change.pricing}
              defaultValue={0}
              value={price}
              placeholder="e.g., 100"
            />
          </FieldWrapper>
        </FieldRow>
      </AddRunnerColumn>
    </ConfigurationCol>
  );
};
