import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { ncImageDataItemsAtom } from 'shared/state/utilState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { Button } from 'antd';
import { Trash } from '@styled-icons/fa-solid';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';
import { ArrowLeftThick } from '@styled-icons/typicons';

const shortid = require('shortid');

interface IComponent {
  view: boolean;
  endCallback: () => void;
}

const ImageReviewWrapper = styled(FlexRow)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  
  position: fixed;
  top: ${(props: any) => (props.view ? 0 : '-100vh')};
  left: 0;
  z-index: 1000000;
  
  transition: top 0.3s ease-in-out;
`;

const ImageReviewCloseButton = styled(Button)`
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1000;
  top: 24px;
  left: 28px;
  border-radius: 50%;
  box-shadow: ${`1px 2px 2px ${rgba(theme.palette.neutral.black, 0.2)}`};
`;

const ImageDoneIcon = styled(ArrowLeftThick)`
  width: 24px;
  margin-bottom: 2px;
`;

const ImageDeleteButton = styled(ImageReviewCloseButton)`
  left: unset;
  right: 24px;
`;

const ImageDeleteIcon = styled(Trash)`
  color: ${theme.palette.neutral.white};
  width: 18px;
`;

const ImageCanvasRow = styled(FlexRow)`
  width: ${(props: any) => `${props.imageCount * 100}vw`};
  align-items: center;
  justify-content: space-between;
  position: relative;
  left: ${(props: any) => `${props.left}vw`};
  transition: left 0.3s ease-in-out; 
`;

const ImageCanvas = styled(FlexColumn)`
  width: 100vw;
  height: 100vh;
  background-color: white;
  //background-image: ${(props: any) => `url(${props.imgSrc})`};
  background-repeat: no-repeat;
  background-size: contain;
  
  & img {
    width: 100vw;
    height: auto;
  }
`;

const ImageNavRow = styled(FlexRow)`
  width: 100%;
  left: 0px;
  bottom: 24px;
  gap: 24px;
  position: absolute;
  z-index: 1000;
`;

const ImageNavButton = styled(Button)`
  border-radius: 12px;
  opacity: ${(props: any) => (props.visible ? 1 : 0)};
`;

export default ({ view, endCallback }: IComponent) => {
  const [imageDataItems, setImageDataItems] = useRecoilState(ncImageDataItemsAtom);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [canvasLeft, setCanvasLeft] = useState((imageDataItems.length - 1) * 50);

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (currentImageIndex > imageDataItems.length - 1) return;
    setCurrentImageIndex(currentImageIndex + 1);
    setCanvasLeft(canvasLeft - 100);
  };

  const onBackClick = (e: any) => {
    e.preventDefault();
    if (currentImageIndex - 1 < 0) return;

    setCurrentImageIndex(currentImageIndex - 1);
    setCanvasLeft(canvasLeft + 100);
  };

  const onDeleteImage = (e: any) => {
    e.preventDefault();
    const newList = [...imageDataItems.slice(0, currentImageIndex), ...imageDataItems.slice(currentImageIndex + 1)];
    setImageDataItems(newList || []);

    if (newList.length === 0) endCallback(e);
    else {
      setCanvasLeft(canvasLeft + 50);
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  useEffect(() => {

  }, [imageDataItems]);

  return (
    <ImageReviewWrapper view={view}>
      <ImageReviewCloseButton type="primary" onClick={endCallback} icon={<ImageDoneIcon />} />
      <ImageDeleteButton type="danger" onClick={onDeleteImage} icon={<ImageDeleteIcon />} />
      <ImageCanvasRow imageCount={imageDataItems.length} left={canvasLeft}>
        {imageDataItems.map((imageData, index) => (
          <ImageCanvas key={`review-image-${shortid.generate()}`}>
            {/* <ImageCanvas key={`review-image-${shortid.generate()}`} imgSrc={imageData}> */}
            <img alt={`foobar-${index}`} src={imageData} />
          </ImageCanvas>
        ))}
      </ImageCanvasRow>
      <ImageNavRow>
        <ImageNavButton disabled={currentImageIndex === 0} visible={currentImageIndex !== 0} onClick={onBackClick}>Back</ImageNavButton>
        <ImageNavButton disabled={currentImageIndex === imageDataItems.length - 1} visible={currentImageIndex < imageDataItems.length - 1} onClick={onNextClick}>Next</ImageNavButton>
      </ImageNavRow>
    </ImageReviewWrapper>
  );
};
