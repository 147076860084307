import shortid from 'shortid';
import React from 'react';
import styled from 'styled-components';
import { formatMargin, formatPrice } from 'shared/data';
import { SubdirectoryArrowRight, TextSnippet } from '@styled-icons/material';
import { IInventoryPart } from 'shared/types/dbRecords';
import { CellCopy, ParentCellCopy } from 'shared/styledComponents/typographicElements';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import { InactiveIndicator } from 'shared/styledComponents/utility';
import theme from 'shared/theme';
import { Popover, Tooltip } from 'antd';
import ActionButtons from './Buttons/ActionButtons';
import PartSku from '../../Customer/Components/PriceList/Components/PartSku';

const ChildWrapper = styled(FlexRow)`
    justify-content: flex-start;
    margin-left: 8px;
`;
const ChildArrow = styled(SubdirectoryArrowRight)`
    width: 24px;
    margin-bottom: 6px;
    color: ${theme.palette.primary.hue};
`;

const NotesIcon = styled(TextSnippet)`
    width: 18px;
    color: ${theme.palette.neutral[800]};
`;

const SkuWrapper = styled(FlexRow)`
  width: 100%;
  height: calc(100% + 4px);
  justify-content: flex-start;
  gap: 4px;
`;

const UnitPriceCopy = styled(CellCopy)`
    color: ${(props: { margin: number }) => (props.margin === 2 ? theme.palette.neutral.black : props.margin >= 0.25 ? theme.palette.success.D100 : props.margin >= 0.2 ? theme.palette.warning.hue : theme.palette.error.hue)};
    font-weight: ${(props: { margin: number }) => (props.margin === 2 ? 'normal' : 'bolder')};
`;
export const AverageCostCopy = styled(CellCopy)`
    color: ${theme.palette.primary.hue};
    font-weight: bold;
`;

const SKU_COLUMN = {
  title: 'Sku',
  dataIndex: 'Sku',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: record.Parent ? 'right' : 'center',
      },
    },
    children:
  <>
    {record.Parent ? (
      <ChildWrapper>
        <ChildArrow />
        <SkuWrapper>
          <CellCopy key={shortid.generate()}>{record.Sku}</CellCopy>
          {record.notes && (
            <Popover content={record.notes} placement="topRight">
              <NotesIcon />
            </Popover>
          )}
          {!record.Active && (
            <InactiveIndicator>INACTIVE</InactiveIndicator>
          )}
        </SkuWrapper>
      </ChildWrapper>
    ) : (
      <>
        {record.Children ? (
          <SkuWrapper>
            <ParentCellCopy key={shortid.generate()}>{record.Sku}</ParentCellCopy>
            {record.notes && (
              <Popover content={record.notes} placement="topRight">
                <NotesIcon />
              </Popover>
            )}
            {!record.Active && (
              <InactiveIndicator>INACTIVE</InactiveIndicator>
            )}
          </SkuWrapper>
        ) : (
          <SkuWrapper>
            <CellCopy key={shortid.generate()}>{record.Sku}</CellCopy>
            {record.notes && (
              <Popover content={record.notes} placement="topRight">
                <NotesIcon />
              </Popover>
            )}
            {!record.Active && (
              <InactiveIndicator>INACTIVE</InactiveIndicator>
            )}
          </SkuWrapper>
        )}
      </>
    )}
  </>,
  }),
  width: '14%',
};
const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'Description',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.Description}</CellCopy>,
  }),
  width: '60%',
};
const VENDOR_COLUMN = {
  title: 'Vendor',
  dataIndex: 'PrefVendorRef.name',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.Children ? '' : record.PrefVendorRef?.name || ''}</CellCopy>,
  }),
  width: '10%',
};

interface IInventoryMarginPart extends IInventoryPart {
  showInventoryMargin: boolean;
}
const SELL_COLUMN = {
  title: 'Sell',
  dataIndex: 'UnitPrice',
  render: (text: string, record: IInventoryMarginPart) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children:
  <Tooltip title={`${formatMargin(record.PurchaseCost, record.UnitPrice)} margin`}>
    <UnitPriceCopy margin={record.showInventoryMargin ? (record.UnitPrice - record.PurchaseCost) / record.UnitPrice : 2} key={shortid.generate()}>{`${formatPrice(record.UnitPrice)}`}</UnitPriceCopy>
  </Tooltip>,
  }),
  width: '8%',
};
const COST_COLUMN = {
  title: 'Cost',
  dataIndex: 'PurchaseCost',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children:
  <>
    {record.Children?.length ? (
      <Tooltip title={`Average cost: ${formatPrice(record.QtyOnHand * record.averageCost)}`}>
        <AverageCostCopy key={shortid.generate()}>{`${formatPrice(record.averageCost)}`}</AverageCostCopy>
      </Tooltip>
    ) : (
      <Tooltip title={`Unit cost: ${formatPrice(record.QtyOnHand * record.PurchaseCost)}`}>
        <CellCopy key={shortid.generate()}>{`${formatPrice(record.PurchaseCost)}`}</CellCopy>
      </Tooltip>
    )}
  </>,
  }),
  width: '8%',
};

const ACTION_COLUMN = {
  title: '',
  dataIndex: 'actionsColumn',
  render: (text: string, record: IInventoryPart) => ({
    children:
  <ActionButtons inventoryPart={record} />,
  }),
  width: '10%',
};
export {
  SKU_COLUMN,
  DESCRIPTION_COLUMN,
  COST_COLUMN,
  SELL_COLUMN,
  VENDOR_COLUMN,
  ACTION_COLUMN,
};
