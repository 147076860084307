import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';
import { FlexRow } from 'shared/containers/FlexContainer';

const ComponentWrapper = styled(ItemDetailsColumnWrapper)`
  width: ${(props: { width: string | undefined }) => props.width || '100%'};
`;
// @ts-ignore
const FieldInput = styled.textarea`
  width: ${(props: { width: string }) => props.width || '100%'};
  height: ${(props: { height: string }) => props.height || '120px'};
  min-height: ${(props: { height: string }) => (props.height ? 'unset' : '120px')};
  padding: 6px 12px 16px;
  border: 1px solid lightgrey;

  &:focus {
      outline: none !important;
      border: 1px solid ${theme.palette.primary.hue} !important;
      box-shadow: 0 0 4px ${rgba(theme.palette.primary.hue, 0.6)};
  }
}
`;

const LabelWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 4px;
`;

const Label = styled(ItemDetailsLabel)`
  width: auto;
`;

interface IComponent {
  id: string;
  label: string;
  labelStyle?: any;
  placeholder: string;
  value: string;
  changeCallback: (value: string) => void;
  blurCallback: (value: string) => void;
  width?: string;
  height?: string;
  useNumber?: boolean;
  disabled?: boolean
  LabelExtra?: any
  style?: any;
}

// Provides a text area with a callback for the value change and blur
const DetailTextAreaWithCallback = ({
  id, value, blurCallback, changeCallback, label, placeholder, width, height, disabled = false, LabelExtra = <div />, style = {}, labelStyle = {},
}: IComponent) => {
  const [fieldValue, setFieldValue] = useState<string|undefined>(value);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onChange = (e: any) => {
    setFieldValue(e.target.value);
    changeCallback(e.target.value);
  };

  const onBlur = (e: any) => {
    setFieldValue(e.target.value);
    blurCallback(fieldValue || e.target.value);
  };

  useEffect(() => {
    setFieldValue(value);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <ComponentWrapper width={width} key="part-desc-item-details-wrapper">
      <LabelWrapper>
        <Label key={`${id}-item-details-wrapper`} style={labelStyle}>{label}</Label>
        {LabelExtra}
      </LabelWrapper>
      <FieldInput
        ref={textAreaRef}
        style={style}
        width={width}
        height={height}
        key={`${id}-input-field`}
        id={`${id}-input-field`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        // onFocusOut={onBlur}
        value={fieldValue}
        disabled={disabled}
      />
    </ComponentWrapper>
  );
};

export default DetailTextAreaWithCallback;
