import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Button, InputNumber } from 'antd';
import { useRecoilState } from 'recoil';
import { shoppingCartAtom, ICartItem } from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import theme from 'shared/theme';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';

const ModalContent = styled(FlexColumn)`
  gap: 24px;
  padding: 8px 0;
`;

const ItemInfo = styled(FlexColumn)`
  width: calc(100% - 8px);
  align-items: flex-start;
`;

const ItemSku = styled.div`
  font-size: 14px;
  font-weight: bolder;
  color: ${theme.palette.neutral[600]};
`;

const ItemDescription = styled.div`
  font-size: 16px;
  color: ${theme.palette.neutral.black};
  margin-top: 4px;
`;

const QuantityRow = styled(FlexRow)`
  width: calc(100% - 8px);
  justify-content: flex-start;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  width: 800px !important;
  
  .ant-modal-content {
    border-radius: 12px;
  }
  
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }
  
  .ant-modal-title {
    font-size: 20px;
    font-weight: 500;
  }
  
  .ant-modal-footer {
    border-radius: 0 0 12px 12px;
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  item: {
    Sku: string;
    Description: string;
    price: number;
  } | null;
}

export default ({ isOpen, onClose, item }: IProps) => {
  const [cartItems, setCartItems] = useRecoilState(shoppingCartAtom);
  const [quantity, setQuantity] = useState(2);
  const [notes, setNotes] = useState('');

  // Load initial cart state from localStorage
  useEffect(() => {
    const savedCart = localStorage.getItem('shoppingCart');
    if (savedCart) {
      try {
        setCartItems(JSON.parse(savedCart));
      } catch (e) {
        console.error('Error loading cart from localStorage:', e);
      }
    }
  }, []);

  const handleAddToCart = () => {
    if (!item) return;

    const newItem: ICartItem = {
      Sku: item.Sku,
      config: item.config,
      Description: item.Description,
      quantity,
      notes,
      price: item.price,
    };

    const updatedCart = [...cartItems, newItem];
    setCartItems(updatedCart);
    localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    
    onClose();
    setQuantity(2);
    setNotes('');
  };

  const partType = ['GN', 'BN'].includes(item?.Description.substring(0, 2)) ? 'Neck' : 'Body';
  const placeholder = `Write any comments or changes to this part e.g., ${partType === 'Neck' ? 'make with rosewood not ebony' : 'make for an FAS hardtail'}...`;

  if (!item) return null;

  return (
    <StyledModal
      title="Add to Order"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="add" type="primary" onClick={handleAddToCart}>
          Add to Order
        </Button>,
      ]}
    >
      <ModalContent>
        <ItemInfo>
          <ItemSku>{item.Sku}</ItemSku>
          <ItemDescription>{item.Description}</ItemDescription>
        </ItemInfo>
        <QuantityRow>
          <DetailNumberInputWithCallback
            width="60px"
            label="Quantity"
            value={quantity}
            callback={(value) => setQuantity(value)}
            min={1}
            id="quantity"
          />
        </QuantityRow>
        <DetailTextAreaWithCallback
          value={notes}
          changeCallback={setNotes}
          blurCallback={setNotes}
          placeholder={placeholder}
          id="notes"
          label="Comments/Changes"
        />
      </ModalContent>
    </StyledModal>
  );
}; 
