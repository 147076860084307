import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';
import { INCRecord } from 'shared/types/dbRecords';

const shortid = require('shortid');

interface IComponent {
  record: INCRecord;
}

const ComponentWrapper = styled(FlexColumn)`
  justify-content: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 2px solid ${rgba(theme.palette.neutral.black, 0.3)};
  gap: 8px;
  background-color: ${theme.palette.neutral.white};
`;

const DetailRow = styled(FlexRow)`
  border-bottom: 1px solid lightgrey;
  justify-content: flex-start;
  width: calc(100% - 24px);
  text-align: left;
  padding: 0 12px;
  
  & h3 {
    min-width: 120px; 
  }
  & p {
    font-size; 12px;
  }
  & * {
    margin: 4px 0;
  }
`;

export default ({ record }: IComponent) => (
  <ComponentWrapper key={shortid.generate()}>
    <DetailRow>
      <h3>Part #</h3>
      <p>{`${record.partNumber} - ${record.partDescription}`}</p>
    </DetailRow>
    <DetailRow>
      <h3>Order #</h3>
      <p>{`${record.salesOrder.orderNumber} - ${record.workOrder}`}</p>
    </DetailRow>
    <DetailRow style={{ borderBottom: 'none' }}>
      <h3>Notes</h3>
      <p>{record.notes}</p>
    </DetailRow>
  </ComponentWrapper>
);
