import React, { useContext } from 'react';
import { Button } from 'antd';
import useFirebase from 'vendor/Firebase';
import firebase from 'firebase/app';
import styled from 'styled-components';
import 'firebase/firestore';
import _, {
  find,
  includes,
  pad,
  update,
  flattenDeep,
  flatten,
  trim,
  differenceBy,
} from 'lodash';
import QBOItem from 'shared/data/QBO/item';
import {
  IBomItem,
  ICustomerRecord,
  IInventoryPart,
  IOrderItem,
  IProductCode,
  IQATicket,
  IShipment,
  IShipmentItem,
} from 'shared/types/dbRecords';
import axios from 'axios';
// import firebase from 'firebase';
import shortid from 'shortid';
import {
  aggregateTermsByType,
  isSamePart,
  sanitizePartDescription,
  configToDescription,
  descriptionToConfig,
  updateConfig,
} from 'shared/partParser/util';
import neckParser from 'shared/partParser/neckParser';
import { partBom } from 'shared/data/jb_api';
import { IRunner, ISalesOrder, IShopOrder } from 'pages/Orders/types';
import bodyParser, {
  bodyWood,
  parseBodiesFromPartData,
  pickups,
} from 'shared/partParser/bodyParser';
import {
  resolveDescription,
  resolveArchetype,
  resolveModel,
  resolveWeight,
  resolvePickups,
  resolveWood,
  resolveInlay,
  resolveNeckShape,
} from 'shared/partParser/partResolver';
import { updateOrderItemBomConsumption } from 'shared/data/order';
import models from 'shared/partParser/models';
import { termTypes, terms } from 'shared/partParser/terms';
import { extractSpecies, partType } from 'shared/partParser/helpers';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import compareParts, {
  establishPartLineages,
  findParent,
} from 'shared/partParser/partComparator';
import {
  partConfigTermsAtom,
  partConfigTermTypesAtom,
} from 'shared/state/partViewState';
import { cleanMeta } from 'shared/text';
import { useRecoilValue } from 'recoil';
import { IConfigEntry, IConfigTerm, ICustomerPart } from 'shared/types/parts';
import { IPartWood } from 'shared/types/pricingTool';

const AdHocButton = styled(Button)`
  border-radius: 8px;
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
  margin-right: 8px;
`;

interface ParsedTerms {
  [key: string]: string[];
}

export default () => {
  const { functions, firestore, database } = useFirebase();
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const configTermTypes = useRecoilValue(partConfigTermTypesAtom);
  const { currentUser } = useContext(AuthContext);

  const fetchBom = async (sku: string) => {
    const bomDoc = await firestore.collection('part-bom-data').doc(sku).get();
    if (!bomDoc.exists) return null;
    // @ts-ignore
    return bomDoc.data().bom as IBomItem[];
  };

  const splitTerms = async () => {
    const termDocs = await firestore.collection('part-config-terms-dev').get();
    const _terms = termDocs.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    const existingTerms = new Map(
      termDocs.docs.map((doc) => [
        doc.data().term,
        { id: doc.id, ...doc.data() },
      ]),
    );

    // Helper function to split compound terms
    const splitCompoundTerms = async (tterms: any[]) => {
      const processedTerms: IConfigTerm[] = [];

      tterms.forEach((term) => {
        // Split terms that contain '/' or other separators
        const splitVariants = term.data.term
          .split(/[/+]/)
          .map((t: string) => t.trim());

        // If it's a compound term, we'll delete it
        if (splitVariants.length > 1) {
          console.log(`Deleting compound term: ${term.data.term}`);
          firestore.collection('part-config-terms-dev').doc(term.id).delete();
          return;
        }

        splitVariants.forEach((variant: string) => {
          // Check if the term already exists in the database
          const existingTerm = existingTerms.get(variant);

          if (existingTerm) {
            // Use the existing term, prioritizing coercion if it exists
            const finalTerm: IConfigTerm = {
              id: existingTerm.id,
              term: existingTerm.coercion || variant,
              type: term.data.type,
              english: existingTerm.english,
              coercion: existingTerm.coercion,
            };

            processedTerms.push(finalTerm);
          } else {
            // If the term doesn't exist, add it
            const newTerm: IConfigTerm = {
              id: shortid.generate(), // Generate a new ID
              term: variant,
              type: term.data.type,
            };

            processedTerms.push(newTerm);
          }
        });
      });

      // Remove duplicates while preserving the most complete term
      const uniqueTerms = Array.from(
        new Map(
          processedTerms.map((term) => [
            term.term,
            processedTerms.find((t) => t.term === term.term) || term,
          ]),
        ).values(),
      );

      return uniqueTerms;
    };

    // Process each term type
    const _termTypes = ['Wood', 'Inlay', 'Pickups'];
    _termTypes.forEach(async (type) => {
      const typedTerms = _terms.filter((t) => t.data.type === type);
      const processedTerms = await splitCompoundTerms(typedTerms);

      console.log(`Processing ${type} terms:`);
      console.log('Original count:', typedTerms.length);
      console.log('Processed count:', processedTerms.length);

      // Batch write processed terms back to Firestore
      const batch = firestore.batch();
      processedTerms.forEach((term) => {
        // Only write if the term doesn't already exist
        if (!existingTerms.has(term.term)) {
          const docRef = firestore
            .collection('part-config-terms-dev')
            .doc(term.id);
          batch.set(docRef, {
            term: term.term,
            type,
            ...(term.english && { english: term.english }),
            ...(term.coercion && { coercion: term.coercion }),
          });
        }
      });

      // Commit the batch write
      await batch.commit();
    });
  };

  const establishLineages = async () => {
    const partDocs = await firestore
      .collection('parts')
      .where('customer', '==', 'LULLG')
      .get();
    const parts = partDocs.docs.map((doc) => doc.data()) as ICustomerPart[];
    const lineage = establishPartLineages(parts, configTerms);

    console.log(lineage);

    // const skusToUpdate: string[] = [];
    const BATCH_SIZE = 500;

    // Convert lineage to array of entries
    const lineageEntries = Object.entries(lineage);

    // Prepare chunks for processing
    const chunks = [];
    for (let i = 0; i < lineageEntries.length; i += BATCH_SIZE) {
      chunks.push(lineageEntries.slice(i, i + BATCH_SIZE));
    }

    // Process chunks sequentially
    await chunks.reduce(async (previousPromise, chunk) => {
      await previousPromise;
      const chunkBatch = firestore.batch();
      const chunkSkusToUpdate: string[] = [];

      // First, get all documents in the chunk
      const docSnapshots = await Promise.all(
        chunk.map(([sku]) => firestore.collection('parts-dev').doc(sku).get()),
      );

      // Then process the snapshots
      docSnapshots.forEach((doc, index) => {
        const [sku, { parent, childParts }] = chunk[index];

        if (doc.exists) {
          const docRef = firestore.collection('parts-test').doc(sku);
          chunkBatch.update(docRef, { parent, childParts });
          chunkSkusToUpdate.push(sku);
        } else {
          console.warn(`Document for SKU ${sku} does not exist`);
        }
      });

      try {
        await chunkBatch.commit();
        console.log(`Updated ${chunkSkusToUpdate.length} parts in this chunk`);
        skusToUpdate.push(...chunkSkusToUpdate);
      } catch (error) {
        console.error('Error updating parts lineage chunk:', error);
      }

      return Promise.resolve();
    }, Promise.resolve());

    console.log(`Total parts updated: ${skusToUpdate.length}`);
  };

  const removeUndefinedTerms = (config: any[]): any[] =>
    config
      .map((configEntry) => {
        // If the entry has terms, filter out undefined terms
        if (configEntry.terms && Array.isArray(configEntry.terms)) {
          return {
            ...configEntry,
            terms: configEntry.terms.filter(
              (term: string) =>
                term !== 'undefined'
                && term !== 'undefinedundefined'
                && !term.match(/undefined/i),
            ),
          };
        }
        return configEntry;
      })
      .filter((configEntry) => {
        // If the entry has terms, ensure it's not completely empty after filtering
        if (configEntry.terms) {
          return configEntry.terms.length > 0;
        }
        return true; // Keep entries without terms
      });

  const checkMaterialsConfirmed = async (order: any) => {
    const itemsDoc = await firestore.collection('order-items').doc(order.id).get();
    const items = itemsDoc.data();
    const hasItems = items?.orderItems?.filter((i) => i).length > 0;
    const itemsConfirmed = hasItems && _.every(items?.orderItems?.map((i) => i.materialsConfirmed), (t) => t);
    if (!hasItems && !itemsConfirmed && order.materialsConfirmed) {
      console.log(`Order ${order.id} has no items but is confirmed`);
      firestore.collection('orders').doc(order.id).update({ materialsConfirmed: null, materialsConfirmedBy: null }).then(() => {
        console.log(`Updated ${order.id}`);
      });
    }
  };

  const main = async () => {
    const orderDocs = await firestore.collection('orders').where('completed', '!=', true).get();
    const orders = orderDocs.docs.map((d) => d.data()).filter((o) => !o.completed);
    const orderIds = orders.map((o) => o.id);

    const orderItemsDocs = await Promise.all(orders.map((o) => firestore.collection('order-items').doc(o.id).get()));
    const orderItems = orderItemsDocs.map((d) => ({ id: d.id, data: d.data() }));

    orders.forEach((o) => {
      const items = _.find(orderItems, (i) => i.id === o.id);
      if (!items) return;
      console.log(items.data);
      firestore.collection('order-items-dev').doc(o.id).set(items.data).then(() => {
        console.log(`Created ${o.id}`);
      });
    });
    // const devPartDocs = await firestore.collection('parts-dev').get();
    // const devParts = devPartDocs.docs.map((d) => d.data());
    // parts.forEach((p) => {
    //   const devPart = _.find(devParts, (dp) => dp.Sku === p.Sku);
    //   if (!devPart) {
    //     firestore.collection('parts-dev').doc(p.Sku).set(p).then(() => {
    //       console.log(`Created ${p.Sku}`);
    //     });
    //   }
    // });

    // partPricing.forEach((p) => {
    //   const part = _.find(parts, { Sku: p.Sku });
    //   if (!part) return;
    //   const neckShape = resolveNeckShape(part?.config || []);
    //   if (!neckShape.isCompoundRadius) return;

    //   console.log(`Inspecing ${p.Sku}: ${configToDescription(part?.config || [])}`);
    //   const updatedPricing = {
    //     ...p.data,
    //   };

    //   if (!_.find(updatedPricing.options.constructionOptions, (o) => o.id === 'nOyOjLN_W--')) {
    //     console.log(`${p.Sku} does not have compound radius option checked`);
    //     updatedPricing.options.constructionOptions.push({
    //       id: 'nOyOjLN_W--',
    //       name: 'Compound Radius',
    //       price: 22,
    //       type: 'constructionOptions',
    //       selected: false,
    //     });
    //   }
    //   firestore.collection('part-pricing-data').doc(p.Sku).set(updatedPricing);
    // });

    // Object.entries(lineage).forEach(([sku, { parent, childParts }]) => {
    //   firestore.collection('parts').doc(sku).update({ parent, childParts }).then(() => {
    //     console.log(`Updated ${sku}`);
    //   });
    // });

    // const partTerms = _.uniq(_.flattenDeep(partsArray.map((p) => p.config.map((c) => c.terms))));

    // const configTermDocs = await firestore.collection('part-config-terms').get();
    // configTermDocs.docs.forEach((doc) => {
    //   const data = doc.data();
    //   if (!_.includes(partTerms, data.term)) {
    //     console.log(`${data.term} is not in use`);
    //     firestore.collection('part-config-terms-archive').doc(doc.id).set(data).then(() => {
    //       firestore.collection('part-config-terms').doc(doc.id).delete().then(() => {
    //         console.log(`Deleted ${data.term}`);
    //       });
    //     });
    //   }
    // });
  };

  const onClick = (e: any) => {
    e.preventDefault();
    if (process.env.NODE_ENV !== 'development') return;
    console.log('Beginning ad hoc routine');

    main().then(() => {
      console.log('Ad hoc routine complete');
    });
  };

  return (
    // @ts-ignore
    <AdHocButton
      disabled={window.location.pathname.match('wildwood')}
      type="default"
      onClick={onClick}
    >
      Execute Ad-hoc function
    </AdHocButton>
  );
};
