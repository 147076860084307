import React from 'react';
import {
  Page, Font, Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import { IShippingInfo } from 'shared/types/order';
import { IOrderItem } from 'shared/types/dbRecords';
import { flatten } from 'lodash';
import ShippingLineItem from '../Components/ShippingLineItem';
import Arrow48 from '../Resources/arrow_48.png';
import { IShopOrder, IWorkOrder } from '../../Orders/types';

interface IComponent {
  order: IShopOrder;
  items: IOrderItem[];
  shippingData: IShippingInfo;
}

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

const PAGE_WIDTH = 11 * 72;
const HORIZONTAL_MARGINS = 18;
const VERTICAL_MARGINS = 36;
const CELL_W = 30;
const CELL_H = 24;

const style = StyleSheet.create({
  shippingPage: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: VERTICAL_MARGINS * 2,
    paddingLeft: HORIZONTAL_MARGINS,
    paddingRight: HORIZONTAL_MARGINS,
    paddingBottom: VERTICAL_MARGINS,
  },
  headerWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
  },
  headerColumn: {
    width: PAGE_WIDTH - (2 * HORIZONTAL_MARGINS) - (11 * CELL_W),
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 4,
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginLeft: 10,
  },
  documentName: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 16,
    margin: 0,
    marginRight: 8,
  },
  salesOrderNumber: {
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    fontSize: 12,
    margin: 0,
    marginBottom: 2,
  },
  customer: {
    fontFamily: 'Red Hat Display-300',
    fontWeight: 'light',
    fontSize: 28,
    marginLeft: 10,
  },
  customerAddressRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 20,
  },
  customerAddressBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  customerShippingAccountLabel: {
    width: 50,
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 9,
    marginRight: 8,
  },
  customerShippingAccountValue: {
    width: 40,
    textAlign: 'left',
    fontFamily: 'Red Hat Display',
    fontSize: 9,
  },
  customerShippingAccount: {
    borderRadius: 12,
    marginLeft: 16,
    padding: 8,
  },
  customerShippingAccountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
    backgroundColor: 'yellow',
  },
  shippingData: {
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    fontSize: 10,
    // lineHeight: 14,
    margin: 0,
    marginLeft: 10,
  },

  // Shipping metrics grid
  shippingMetricsColumn: {
    width: 11 * CELL_W,
    height: 'auto',
  },
  boxDetailRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: CELL_H,
  },
  leftBorder: {
    borderLeft: '1px solid black',
  },
  boxNumberContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: CELL_W,
    height: CELL_H,
    paddingBottom: 4,
    // borderBottom: '1px solid black',
  },
  boxNumberLabel: {
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    fontSize: 9,
  },
  boxDetailLabelWrapper: {
    width: CELL_W * 2,
    height: CELL_H,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 8,
    borderRight: '1px solid black',
  },
  boxDetailLabel: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 10,
  },
  shipMetricBox: {
    width: CELL_W,
    height: CELL_H,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid black',
    borderRight: '1px solid black',
  },
  shipMetric: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 8,
  },
  shipArrow: {
    width: 20,
    height: 20,
  },
  shippingItemsWrapper: {
    width: PAGE_WIDTH - (2 * HORIZONTAL_MARGINS) - 10,
    marginLeft: 10,
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    overflow: 'hidden',
  },
});
const ShippingPage = ({ order, items }: IComponent) => (
  <Page size="LETTER" orientation="landscape" style={style.shippingPage}>
    <View style={style.headerWrapper}>
      <View style={style.headerColumn}>
        <View style={style.headerRow}>
          <Text style={style.documentName}>QA + Shipping</Text>
          <Text style={style.salesOrderNumber}>{`SO #1${order.salesOrder}`}</Text>
        </View>
        <Text style={style.customer}>{order.customer.shortName}</Text>
        {order.customer && (
        <View style={style.customerAddressRow}>
          <View style={style.customerAddressBlock}>
            <Text style={style.shippingData}>{order.customer.ShipAddr.Line1}</Text>
            {!!order.customer?.ShipAddr?.Line2 && (
            <Text style={style.shippingData}>{order.customer.ShipAddr.Line2}</Text>
            )}
            {!!order.customer?.ShipAddr?.Line3 && (
            <Text style={style.shippingData}>{order.customer.ShipAddr.Line3}</Text>
            )}
            {!!order.customer?.ShipAddr?.Line4 && (
            <Text style={style.shippingData}>{order.customer.ShipAddr.Line4}</Text>
            )}
            <Text
              style={style.shippingData}
            >
              {`${order.customer.ShipAddr.City}, ${order.customer.ShipAddr.CountrySubDivisionCode}  ${order.customer.ShipAddr.PostalCode}`}
            </Text>
            {!_.includes(['USA', 'United States'], order.customer.ShipAddr.Country) && (
            <Text style={[style.shippingData, { marginBottom: 8 }]}>{order.customer.ShipAddr.Country}</Text>
            )}
          </View>
          {(order.customer.ShipAddr.shippingVendor && order.customer.ShipAddr.shippingAccount && order.customer.ShipAddr.shippingAccount.length > 0 && order.customer.ShipAddr.shippingVendor.length > 0) && (
          <View style={style.customerShippingAccount}>
            <View style={style.customerShippingAccountRow}>
              <Text style={style.customerShippingAccountLabel}>Ship with:</Text>
              <Text style={style.customerShippingAccountValue}>{order.customer.ShipAddr.shippingVendor}</Text>
            </View>
            <View style={style.customerShippingAccountRow}>
              <Text style={style.customerShippingAccountLabel}>Account:</Text>
              <Text style={style.customerShippingAccountValue}>{order.customer.ShipAddr.shippingAccount}</Text>
            </View>
          </View>
          )}
        </View>
        )}
      </View>
      <View style={style.shippingMetricsColumn}>
        <View style={style.boxDetailRow}>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>1</Text>
          </View>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>2</Text>
          </View>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>3</Text>
          </View>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>4</Text>
          </View>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>5</Text>
          </View>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>6</Text>
          </View>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>7</Text>
          </View>
          <View style={style.boxNumberContainer}>
            <Text style={style.boxNumberLabel}>8</Text>
          </View>
        </View>
        <View style={style.boxDetailRow}>
          <View style={style.boxDetailLabelWrapper}>
            <Text style={style.boxDetailLabel}>Box Type</Text>
          </View>
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
        </View>
        <View style={style.boxDetailRow}>
          <View style={style.boxDetailLabelWrapper}>
            <Text style={style.boxDetailLabel}>Weight</Text>
          </View>
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
          <View style={style.shipMetricBox} />
        </View>
        <View style={style.boxDetailRow}>
          <View style={[style.shipMetricBox, style.leftBorder]}>
            <Text style={style.shipMetric}>Order</Text>
          </View>
          <View style={style.shipMetricBox}>
            <Text style={style.shipMetric}>Ship</Text>
          </View>
          <View style={style.shipMetricBox}>
            <Text style={style.shipMetric}>Q/A</Text>
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
          <View style={style.shipMetricBox}>
            <Image src={Arrow48} style={style.shipArrow} />
          </View>
        </View>
      </View>
    </View>

    {!!items.length && (
    <View style={style.shippingItemsWrapper}>
      {flatten(order.runners.map((r: IWorkOrder) => r.parts)).filter((i: IOrderItem) => !i.Sku.match(/^99/)).map((i: IOrderItem) => (<ShippingLineItem item={i} />))}
    </View>
    )}
    <View style={style.boxDetailRow}>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>Box #</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>1</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>2</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>3</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>4</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>5</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>6</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>7</Text>
      </View>
      <View style={style.boxNumberContainer}>
        <Text style={style.boxNumberLabel}>8</Text>
      </View>
    </View>
  </Page>
);
export default ShippingPage;
