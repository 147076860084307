import firebase from 'firebase';
import { IShopOrder } from 'pages/Orders/types';
import { workingDaysBefore } from 'shared/data/calendar';
import { noFinishing, toppedOrBound } from '../data/order';
import { IOrderItem } from '../types/dbRecords';

/**
 * This function checks the provided shipDate against stored dates in localStorage and updates localStorage if needed.
 *
 * The purpose of this method is to store the earliest and latest shipDate for a range of products in localStorage.
 * This data can be useful to understand the range of dates to which the production is scheduled.
 *
 * @param {firebase.firestore.Timestamp} shipDate - The ship date of a product. It is a Timestamp object from the Firebase library.
 *
 * After invoking this function with a shipDate taken from a product, it checks if the date is earlier or later than the stored dates in
 * localStorage. It will update the 'salesOrderStartDate' or 'salesOrderEndDate' in localStorage if needed.
 *
 * Two keys are used in localStorage:
 * 'salesOrderStartDate' - holds the earliest date
 * 'salesOrderEndDate'   - holds the latest date
 *
 * If the provided shipDate is earlier than the date in 'salesOrderStartDate', the function will update 'salesOrderStartDate' with this earlier date.
 * If the shipDate is later than the date in 'salesOrderEndDate', the function will update 'salesOrderEndDate' with this later date.
 *
 */
export const checkProdScheduleDates = (shipDate: firebase.firestore.Timestamp) => {
  const startDate = localStorage.getItem('salesOrderStartDate');
  const endDate = localStorage.getItem('salesOrderEndDate');

  if (!startDate || !endDate) return;

  const checkDate = shipDate.toDate();
  checkDate.setHours(0, 0, 0, 0);

  if (checkDate < new Date(parseInt(startDate, 10))) {
    localStorage.setItem('salesOrderStartDate', checkDate.getTime().toString());
  }

  if (checkDate > new Date(parseInt(endDate, 10))) {
    localStorage.setItem('salesOrderEndDate', checkDate.getTime().toString());
  }
};

export const releaseDate = (order: IShopOrder, orderItems: IOrderItem[] = []) => {
  // if the order type is 1 (neck) assign 11 days to get through the shop, 5 for bodies
  const workingDays = order.type === 'neck' ? 11 : 5;
  const orderToppedOrBound = toppedOrBound(orderItems);
  const orderNeedsFinishing = !noFinishing(orderItems);
  const rd = workingDaysBefore(order.shipDate.toDate(), workingDays, order.type, orderToppedOrBound, orderNeedsFinishing, false, order.ihs, order.outsideFinishRequired);
  return firebase.firestore.Timestamp.fromDate(rd);
};
