import React, { useContext, useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import {
  find, findIndex, flatten, includes, noop, uniq,
} from 'lodash';
import shortid from 'shortid';
import qs from 'qs';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  Button, DatePicker, Divider, Dropdown, InputNumber, Modal, Radio,
} from 'antd';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { FlexRow } from 'shared/containers/FlexContainer';

import { salesOrderEditedAtom } from 'shared/state/routingState';
import { IPartWood } from 'shared/types/pricingTool';
import { costUpdateNotificationUsersAtom, MESSAGE_DB_PATH_ATOM } from 'shared/state/messageState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import {
  AddRunnerColumn, RunnerHeaderRow, RunnerPageTitle, DeleteSalesOrderButton,
} from 'pages/ProductionSchedule/styledComponents';
import {
  DescInput,
  FieldWrapper,
  FieldRow,
  InputCaption,
  SOInput,
} from 'pages/ProductionSchedule/Components/styledComponents';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import { formatPercent, formatPrice } from 'shared/data';
import {
  isProduction, resolveRealtimePath, stageRedirect, useTestData,
} from 'shared/util';
import { inventoryItemsAtom } from '../../../shared/state/inventoryState';

const MarginP = styled.p`
  margin: 0;
`;

const EditPartButton = styled(Button)`
    border-radius: 8px;
`;

export default () => {
  const { currentUser } = useContext(AuthContext);
  const { database } = useFirebase();
  const [partName, setPartName] = useState('');
  const [pricing, setPricing] = useState({ S: 0, M: 0, L: 0 });
  const [partNumbers, setPartNumbers] = useState({ S: 0, M: 0, L: 0 });
  const [materialCost, setMaterialCost] = useState({ S: 0, M: 0, L: 0 });
  const [costUpdated, setCostUpdated] = useState<boolean>(false);
  const [pricingItems, setPricingItems] = useState<IPartWood[]>([]);
  const [type, setType] = useState<'standard'|'tilt'|'any'>('any');
  const [edited, setEdited] = useRecoilState(salesOrderEditedAtom);
  const costUpdateNotificationUsers = useRecoilValue(costUpdateNotificationUsersAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);

  const urlParse = qs.parse(window.location.search.replace('?', ''));
  const woodType = urlParse.woodType;
  const partId = urlParse.partId;
  const partType = resolveRealtimePath(urlParse.partType as string);
  const altPartType = partType.match(/test/i) ? urlParse.partType : partType;

  const handlers = {
    change: {
      partName: (e: any) => {
        if (!edited) setEdited(true);
        setPartName(e.target.value);
      },

      partNumber: (part: 'S'|'M'|'L') => (_partNumber: number, blur: boolean) => {
        if (blur) {
          if (!edited) setEdited(true);
          const _partNumbers = { ...partNumbers };
          _partNumbers[part] = _partNumber;
          setPartNumbers(_partNumbers);

          /*
          Next try to find an inventory part that matches the input part number. If one is found, update price and cost.
           */
          const inventoryItem = find(inventoryItems, (i) => i.Sku === _partNumber.toString());
          if (!inventoryItem) return;
          const _pricing = { ...pricing };
          const _cost = { ...materialCost };

          _pricing[part] = inventoryItem.UnitPrice;
          _cost[part] = inventoryItem.PurchaseCost;

          setPricing(_pricing);
          setMaterialCost(_cost);
        }
      },
      type: (e: any) => {
        if (!edited) setEdited(true);
        setType(e.target.value);
      },
    },
    delete: (e: any) => {
      e.preventDefault();
      Modal.confirm({
        content: 'Are you sure you want to delete this part?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          database.ref(`/${resolveRealtimePath(partType)}/${woodType}/items`).once('value').then((snap) => {
            const data = snap.val() as IPartWood[];
            database.ref(`/${resolveRealtimePath(partType)}/${woodType}/items`).set(data.filter((i: IPartWood) => i.id !== partId)).then(() => {
              if (isProduction()) {
                database.ref(`/${partType}Test/${woodType}/items`).set(data.filter((i: IPartWood) => i.id !== partId)).then(() => {
                  window.location.href = '/pricing/edit';
                });
              } else {
                window.location.href = '/pricing/edit';
              }
            });
          });
        },
      });
    },
    update: (e: any) => {
      e.preventDefault();
      database.ref(`/${resolveRealtimePath(partType)}/${woodType}/items`).once('value').then((snap) => {
        const records = snap.val() as IPartWood[];
        const updateIndex = findIndex(records, (i: IPartWood) => i.id === partId);
        if (costUpdated && !useTestData) {
          const messageId = shortid.generate();
          const notificationUsers = costUpdateNotificationUsers.filter((_user: string) => !currentUser.email.match(new RegExp(_user, 'i')));

          notificationUsers.forEach((_user: string) => {
            database.ref(`${messageDbString}/${_user}/${messageId}`).set({
              id: messageId,
              subject: 'The cost for a part has been updated',
              message: `${currentUser.email.split('@')[0]} just updated the cost of ${partName} in our pricing tool. Verify that the current listed sell price is valid.`,
              clickUrl: window.location.href,
              archived: false,
              read: false,
              sent: new Date().getTime(),
            });
          });
        }
        database.ref(`/${resolveRealtimePath(partType)}/${woodType}/items/${updateIndex}`).update({
          materialCost, price: pricing, partNumber: partNumbers, label: partName,
        }).then(() => {
          window.location.href = '/pricing/edit';
        });
      });
    },
    // eslint-disable-next-line consistent-return
    submit: (e: any) => {
      e.preventDefault();
      if (partId) return handlers.update(e);

      let errorText = null;
      if (partName.length === 0) {
        errorText = 'Please add a label for this part';
      }
      if (!pricing.M && !pricing.L) {
        errorText = 'Please add a price for this part';
      }
      if (errorText) {
        return Modal.error({
          content: errorText,
          okText: 'OK',
        });
      }
      const record = {
        id: shortid.generate(),
        price: pricing,
        materialCost,
        partNumber: { S: partNumbers.S || 0, M: partNumbers.M || 0, L: partNumbers.L || 0 },
        type,
        label: partName,
      };
      database.ref(`/${resolveRealtimePath(partType)}/${woodType}/items`).once('value').then((snap) => {
        const records = snap.val() as IPartWood[];
        const updateIndex = records.length;

        if (costUpdated && !useTestData) {
          const messageId = shortid.generate();
          const notificationUsers = costUpdateNotificationUsers.filter((_user: string) => !currentUser.email.match(new RegExp(_user, 'i')));

          notificationUsers.forEach((_user: string) => {
            database.ref(`${messageDbString}/${_user}/${messageId}`).set({
              id: messageId,
              subject: 'The cost for a part has been updated',
              message: `${currentUser.email.split('@')[0]} just updated the cost of ${partName} in our pricing tool. Verify that the current listed sell price is valid.`,
              clickUrl: window.location.href,
              archived: false,
              read: false,
              sent: new Date().getTime(),
            });
          });
        }
        database.ref(`/${resolveRealtimePath(partType)}/${woodType}/items/${updateIndex}`).set(record).then(() => {
          if (isProduction()) {
            const items = database.ref(`/${partType}Test/${woodType}/items`).once('value').then((s) => {
              const testRecords = s.val() as IPartWood[];
              const newIndex = testRecords.length;
              database.ref(`/${partType}Test/${woodType}/items/${newIndex}`).set(record).then(() => {
                window.location.href = '/pricing/edit';
              });
            });
          } else {
            window.location.href = '/pricing/edit';
          }
        });
      });
    },
  };

  const onEditPart = (sku: string) => (e: any) => {
    e.preventDefault();
    const partTypePath = partType.replace(/Dev|Test/i, '');
    stageRedirect(`/pricing/material/edit?partType=${partTypePath}&woodType=${woodType}&partId=${partId}`);
    window.location.href = `/inventory/edit?partId=${sku}`;
  };

  useEffect(() => {
    database.ref(`/${resolveRealtimePath(partType)}/${woodType}/items`).once('value').then((snap) => {
      setPricingItems(snap.val());
      if (partId) {
        const data = find(snap.val(), (i: IPartWood) => i.id === partId) as IPartWood;
        if (!data) return;

        setPartName(data.label);
        setPricing(data.price);
        setMaterialCost(data.materialCost || { S: 0, M: 0, L: 0 });
        setPartNumbers(data.partNumber || { S: 0, M: 0, L: 0 });
      }
    });
  }, []);

  return (
    <ConfigurationCol>
      <RunnerHeaderRow>
        <RunnerPageTitle>{`${partId ? 'Edit' : 'Add'} Part Material`}</RunnerPageTitle>
        <FlexRow style={{ flexGrow: 2, marginTop: 4, gap: 20 }} justify="flex-start">
          <Button type="primary" shape="round" onClick={handlers.submit}>{partId ? 'Save' : 'Create'}</Button>
          {!!partId
          && <DeleteSalesOrderButton type="primary" onClick={handlers.delete}>Delete</DeleteSalesOrderButton>}
        </FlexRow>
      </RunnerHeaderRow>
      <AddRunnerColumn>
        <Divider key={shortid.generate()} orientation="left">Customer</Divider>
        <FieldRow>
          <FieldWrapper style={{ width: 456 }}>
            <InputCaption>Part name</InputCaption>
            <DescInput
              onChange={handlers.change.partName}
              defaultValue=""
              value={partName}
              placeholder="e.g., 4/4 Maple, Quartersawn"
            />
          </FieldWrapper>
        </FieldRow>

        <Divider key={shortid.generate()} orientation="left">Guitar (Small) Details</Divider>
        <FieldRow>
          <DetailInputWithCallback id="small-part-number" label="Part #" placeholder="e.g., 1500001" value={partNumbers.S || ''} callback={handlers.change.partNumber('S')} extend={false} />
          <DetailInputWithCallback id="small-material-cost" label="Cost" placeholder="Edit in Inventory" value={materialCost?.S ? formatPrice(materialCost.S, 0) : null} callback={noop} extend={false} isLabel disabled />
          <DetailInputWithCallback id="small-material-sell" label="Sell" placeholder="Edit in Inventory" value={pricing?.S ? formatPrice(pricing.S, 0) : 0} callback={noop} extend={false} isLabel disabled />
          <DetailInputWithCallback id="small-material-margin" label="Margin" value={(!!pricing?.S && materialCost?.S) ? formatPercent((1 - (materialCost.S / pricing.S)), 0) : '--'} callback={noop} extend={false} isLabel disabled />
          {partNumbers?.S && (
            <EditPartButton onClick={onEditPart(partNumbers.S)}>Edit Part</EditPartButton>
          )}
        </FieldRow>
        <Divider key={shortid.generate()} orientation="left">Guitar (Medium) Details</Divider>
        <FieldRow>
          <DetailInputWithCallback id="med-part-number" label="Part #" placeholder="e.g., 1500001" value={partNumbers.M || 1} callback={handlers.change.partNumber('M')} extend={false} />
          <DetailInputWithCallback id="med-material-cost" label="Cost" placeholder="Edit in Inventory" value={materialCost?.M ? formatPrice(materialCost.M, 0) : 0} callback={noop} extend={false} isLabel disabled />
          <DetailInputWithCallback id="med-material-sell" label="Sell" placeholder="Edit in Inventory" value={pricing?.M ? formatPrice(pricing.M, 0) : 0} callback={noop} extend={false} isLabel disabled />
          <DetailInputWithCallback id="med-material-margin" label="Margin" value={(!!pricing?.M && materialCost?.M) ? formatPercent((1 - (materialCost.M / pricing.M)), 0) : '--'} callback={noop} extend={false} isLabel disabled />
          {partNumbers?.M && (
            <EditPartButton onClick={onEditPart(partNumbers.M)}>Edit Part</EditPartButton>
          )}
        </FieldRow>
        <Divider key={shortid.generate()} orientation="left">Bass Details</Divider>
        <FieldRow>
          <DetailInputWithCallback id="large-part-number" label="Part #" placeholder="e.g., 1500001" value={partNumbers.L || 1} callback={handlers.change.partNumber('L')} extend={false} />
          <DetailInputWithCallback id="large-material-cost" label="Cost" placeholder="Edit in Inventory" value={materialCost?.L ? formatPrice(materialCost.L, 0) : 0} callback={noop} extend={false} isLabel disabled />
          <DetailInputWithCallback id="large-material-sell" label="Sell" placeholder="Edit in Inventory" value={pricing?.L ? formatPrice(pricing.L, 0) : 0} callback={noop} extend={false} isLabel disabled />
          <DetailInputWithCallback id="large-material-margin" label="Margin" value={(!!pricing?.L && materialCost?.L) ? formatPercent((1 - (materialCost.L / pricing.L)), 0) : '--'} callback={noop} extend={false} isLabel disabled />
          {partNumbers?.L && (
            <EditPartButton onClick={onEditPart(partNumbers.L)}>Edit Part</EditPartButton>
          )}
        </FieldRow>
      </AddRunnerColumn>
    </ConfigurationCol>
  );
};
