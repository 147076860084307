import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import shortid from 'shortid';
import { Button, Table, Tooltip, Modal, Popover } from 'antd';
import { useRecoilValue } from 'recoil';
import { Comment, Check, ArrowBack, TextSnippet, SubdirectoryArrowRight } from '@styled-icons/material';
import { rgba as hexRgba } from 'shared/palette/utils';
import { resolveFirestorePath, stageRedirect } from 'shared/util';
import theme from 'shared/theme';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { includes } from 'lodash';
import { dateString } from 'shared/data/calendar';
import { CellCopy } from 'shared/styledComponents/typographicElements';
import { configToDescription } from 'shared/partParser/util';
import { PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import useFirebase from 'vendor/Firebase';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import { attributeNote } from 'shared/text';
import { ICustomerPart } from 'shared/types/parts';
import { openOrdersDataAtom } from 'shared/state/orderState';

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemTable = styled((props) => <Table {...props} />)`
  width: 100%;
  && tbody > tr:hover > td {
    background: ${hexRgba(theme.palette.primary.hue, 0.2)};
  }
`;

const SkuWrapper = styled(FlexColumn)`
  gap: 4px;
`;

const PartDescriptionRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;

const SkuText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ParentText = styled.span`
  font-size: 12px;
  color: ${theme.palette.neutral[600]};
`;

const ActionButton = styled(Button)`
  border: 1px solid #EEEEEE;
`;

const CheckIcon = styled(Check)`
  width: 24px;
  margin-bottom: 4px;
`;

const CommentIcon = styled(Comment)`
  width: 24px;
  margin-bottom: 4px;
`;

const BackIcon = styled(ArrowBack)`
  width: 24px;
  margin-bottom: 4px;
`;

const NotesIcon = styled(TextSnippet)`
  width: 24px;
  color: ${theme.palette.neutral[800]};
`;

const SkuContainer = styled(FlexRow)`
  width: 100%;
  height: calc(100% + 4px);
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const ParentArrow = styled(SubdirectoryArrowRight)`
  width: 24px;
  margin-bottom: 6px;
  color: ${theme.palette.primary.hue};
  transform: rotate(90deg) scaleX(-1);
  margin-left: -4px;
  margin-right: -4px;
`;

const ParentContainer = styled(FlexRow)`
  align-items: center;
  gap: 2px;
`;

const DatePill = styled.div<{ $warning?: boolean; $error?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 8px;
  font-weight: ${({ $warning, $error }) => ($warning || $error ? 'bold' : 'normal')};
  background-color: ${({ $warning, $error }) => {
    if ($error) return theme.palette.error[600];
    if ($warning) return theme.palette.warning.hue;
    return 'transparent';
  }};
  color: ${({ $warning, $error }) => (($warning || $error) ? theme.palette.neutral.white : theme.palette.neutral.black)};
`;

const NewPill = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  border-radius: 8px;
  background-color: ${theme.palette.success.hue};
  color: ${theme.palette.success[200]};
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
`;

const RevisionPill = styled(NewPill)`
  background-color: ${theme.palette.warning.hue};
  color: ${theme.palette.warning[200]};
`;

const ChildPill = styled(NewPill)`
  background-color: ${theme.palette.primary.hue};
  color: ${theme.palette.primary[200]};
`;

const OtherPill = styled(NewPill)`
  background-color: ${theme.palette.neutral[600]};
  color: ${theme.palette.neutral[100]};
`;

interface Props {
  stage: 'product' | 'material' | 'complete';
  isCompleted: boolean;
  parts: ICustomerPart[];
}

const ProductDevList = ({ stage, isCompleted, parts }: Props) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const openOrders = useRecoilValue(openOrdersDataAtom);
  const partCollectionDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const [_parts, setParts] = useState(parts);

  const addComment = (sku: string) => (e: any) => {
    e.stopPropagation();
    firestore.collection(partCollectionDbString).doc(sku).get().then((doc) => {
      const partNotes = doc.data()?.pdNotes;
      let notesValue = partNotes || '';
      let newNotesValue = notesValue;
      Modal.confirm({
        title: 'Add/update part notes',
        content: (
          <div>
            <p>Add or update product development notes for this part.</p>
            <DetailTextAreaWithCallback 
              key={`${sku}-add-update-product-notes`}
              id={`${sku}-add-update-product-notes`}
              label=""
              changeCallback={(value) => { newNotesValue = value; }}
              blurCallback={(value) => { newNotesValue = value; }}
              value={newNotesValue}
              width="100%"
              placeholder="Add your notes here..."
              style={{ 
                minHeight: '100px',
                marginTop: '8px',
                padding: '8px',
              }}
            />
          </div>
        ),
        onOk: async () => {
          if (!newNotesValue.trim()) {
            Modal.error({
              title: 'Notes Required',
              content: 'Please provide notes explaining why this part needs to return to product development.',
            });
            return Promise.reject();
          }

          notesValue += attributeNote(currentUser, newNotesValue.replace(partNotes, ''));
          await firestore.collection(partCollectionDbString).doc(sku).update({ 
            new: true,
            pdCompletedDate: null,
            pdNotes: notesValue,
            lastUpdated: firebase.firestore.Timestamp.now(),
            lastUpdatedBy: currentUser?.email || '',
          });
          return Promise.resolve();
        },
        okText: partNotes?.length > 0 ? 'Update note' : 'Add note',
        cancelText: 'Cancel',
        width: 500,
      });
    });
  };
  
  const moveToMaterials = (sku: string) => (e: any) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Complete Development',
      content: 'Mark development complete for this part?',
      onOk: async () => { 
        await firestore.collection(partCollectionDbString).doc(sku).update({ 
          new: false,
          pdCompletedDate: firebase.firestore.Timestamp.now(),
          revisionNeeded: false,
        });
        const partDoc = await firestore.collection(partCollectionDbString).doc(sku).get();
        const part = partDoc.data() as ICustomerPart;
        if (part.childParts) {
          await Promise.all(part.childParts.map((p) => 
            firestore.collection(partCollectionDbString).doc(p).update({ 
              revisionNeeded: false,
              pdNotes: part.pdNotes,
            })));
        }
        if (part.parent) {
          const parentDoc = await firestore.collection(partCollectionDbString).doc(part.parent as string).get();
          const parentPart = parentDoc.data() as ICustomerPart;
          const allRelatedSkus = [part.parent as string, ...(parentPart.childParts || [])];
          
          await Promise.all(allRelatedSkus.map((_sku: string) => 
            firestore.collection(partCollectionDbString).doc(_sku).update({
              new: false,
              pdCompletedDate: firebase.firestore.Timestamp.now(),
              revisionNeeded: false,
              lastUpdated: firebase.firestore.Timestamp.now(),
              lastUpdatedBy: currentUser?.email || '',
            })));
        }
      },
    });
  };

  const moveBackToProduct = (sku: string) => (e: any) => {
    e.stopPropagation();
    let notesValue = '';
    
    Modal.confirm({
      title: 'Move back to Product',
      content: (
        <div>
          <p>Add a note explaining why this part needs to return to product development.</p>
          <DetailTextAreaWithCallback 
            key={`${sku}-send-back-to-product-notes`}
            id={`${sku}-send-back-to-product-notes`}
            label=""
            changeCallback={(value) => { notesValue = value; }}
            blurCallback={(value) => { notesValue = value; }}
            value={notesValue}
            width="100%"
            placeholder="Required: Add your notes here..."
            style={{ 
              minHeight: '100px',
              marginTop: '8px',
              padding: '8px',
            }}
          />
        </div>
      ),
      onOk: async () => {
        if (!notesValue.trim()) {
          Modal.error({
            title: 'Notes Required',
            content: 'Please provide notes explaining why this part needs to return to product development.',
          });
          return Promise.reject();
        }
        
        await firestore.collection(partCollectionDbString).doc(sku).update({ 
          new: true,
          pdCompletedDate: null,
          pdNotes: attributeNote(currentUser, notesValue),
          lastUpdated: firebase.firestore.Timestamp.now(),
          lastUpdatedBy: currentUser?.email || '',
        });
        return Promise.resolve();
      },
      okText: 'Move to Product',
      cancelText: 'Cancel',
      width: 500,
    });
  };
  
  const moveToComplete = (sku: string) => (e: any) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Mark Complete',
      content: 'Mark this part as complete and ready for production?',
      onOk: async () => {
        const timestamp = firebase.firestore.Timestamp.now();
        const confirmedBy = currentUser?.email || '';

        // Find and update orders containing this SKU
        const orderUpdates = openOrders
          .filter((order) => order.orderItems?.some((item) => item?.Sku === sku))
          .map((order) => {
            const updatedOrderItems = order.orderItems.map((item) => 
              (item.Sku === sku 
                ? { ...item, materialsConfirmed: timestamp, materialsConfirmedBy: confirmedBy, pdRequirement: null, pdNotes: null }
                : item));

            return firestore
              .collection(resolveFirestorePath('order-items'))
              .doc(order.id)
              .update({ orderItems: updatedOrderItems });
          });

        await Promise.all(orderUpdates);
        // Update the part in the parts collection
        await firestore.collection(partCollectionDbString).doc(sku).update({ 
          materialsConfirmed: timestamp,
          materialsConfirmedBy: confirmedBy,
          lastUpdated: timestamp,
          lastUpdatedBy: confirmedBy,
          pdRequirement: null,
        });
      },
    });
  };

  const filteredParts = _parts.filter((part) => {
    if (stage === 'product') {
      // revising the parent will revise all children, so don't show revisions if they have a parent
      if (part.pdRequirement === 'Revision') return !part.pdCompletedDate && !part.parent;
      return !part.pdCompletedDate;
    }
    if (stage === 'material') {
      return !!part.pdCompletedDate && !part.materialsConfirmed;
    }
    return false;
  });

  const onRowClick = (record: any) => () => {
    stageRedirect();
    const partType = includes(['GN', 'BN'], record.type) ? 'neck' : 'body';
    window.location.href = `/pricing/${partType}?partId=${record.Sku}`;
  };

  useEffect(() => {
    setParts(parts);
  }, [parts]);

  const formatNotes = (notes: string) => (notes.replace(/\[/g, '\n[').trim());

  const NEW_PART_SKU_COLUMN = {
    title: 'Sku',
    dataIndex: 'Sku',
    render: (text: string, record: any) => ({
      props: {
        style: {
          textAlign: 'left',
        },
      },
      children: (
        <SkuContainer>
          <SkuWrapper>
            <SkuText>
              {record.Sku}
              {record.pdRequirement === 'New' && (
                <NewPill>{record.pdRequirement.toUpperCase()}</NewPill>
              )}
              {record.pdRequirement === 'Variant' && (
                <ChildPill>{record.pdRequirement.toUpperCase()}</ChildPill>
              )}
              {record.pdRequirement === 'Revision' && (
                <RevisionPill>{record.pdRequirement.toUpperCase()}</RevisionPill>
              )}
              {record.pdRequirement === 'Other' && (
                <OtherPill>{record.pdRequirement.toUpperCase()}</OtherPill>
              )}
            </SkuText>
            {record.parent && (
              <ParentContainer>
                <ParentArrow />
                <ParentText>{record.parent}</ParentText>
              </ParentContainer>
            )}
          </SkuWrapper>
          {record.pdNotes && (
            <Popover 
              content={<pre style={{ margin: 0, whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>{formatNotes(record.pdNotes)}</pre>} 
              placement="top"
            >
              <NotesIcon />
            </Popover>
          )}
        </SkuContainer>
      ),
    }),
    width: '15%',
    sorter: (a, b) => a.Sku.localeCompare(b.Sku),
  };
  
  const DESCRIPTION_COLUMN = {
    title: 'Description',
    dataIndex: 'Description',
    render: (text: string, record: any) => ({
      props: {
        style: {
          textAlign: 'left',
        },
      },
      children:
      <PartDescriptionRow>
        <CellCopy key={shortid.generate()}>{configToDescription(record.config)}</CellCopy>
      </PartDescriptionRow>,
    }),
    width: '65%',
  };
  
  const CREATED_DATE_COLUMN = {
    title: 'Created Date',
    dataIndex: 'createdDate',
    render: (text: string, record: any) => dateString(new Date(record.createdDate.seconds * 1000), true),
    width: '10%',
  };

  const RELEASE_DATE_COLUMN = {
    title: 'ReleaseDate',
    dataIndex: 'releaseDate',
    render: (text: string, record: any) => {
      if (!record.releaseDate) return '--';
      
      const releaseDate = new Date(record.releaseDate.seconds * 1000);
      const today = new Date();
      const diffDays = Math.ceil((releaseDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      
      return (
        <DatePill 
          $error={diffDays <= 2}
          $warning={diffDays > 2 && diffDays <= 7}
        >
          {dateString(releaseDate, true)}
        </DatePill>
      );
    },
    width: '10%',
    sorter: (a, b) => {
      if (!a.releaseDate || !b.releaseDate) {
        return !a.releaseDate ? 1 : -1;
      }
      return a.releaseDate.seconds - b.releaseDate.seconds;
    },
    defaultSortOrder: 'ascend',
  };
  
  const CREATED_BY_COLUMN = {
    title: 'Created By',
    dataIndex: 'createdBy',
    render: (text: string, record: any) => {
      const firstName = record.createdBy.split('@')[0].slice(0, -1);
      return firstName.charAt(0).toUpperCase() + firstName.slice(1);
    },
    width: '10%',
  };
  
  const ACTION_COLUMN = (_stage: 'product'|'material') => ({
    title: '',
    dataIndex: 'actionsColumn',
    render: (text: string, record: any) => ({
      children:
      <FlexRow style={{ gap: 8 }}>
        {_stage === 'product' && (
        <Tooltip title="Mark Complete" placement="top">
          <ActionButton
            key={`${record.Sku}-move-to-materials`}
            shape="circle"
            type="ghost"
            icon={<CheckIcon />}
            onClick={moveToMaterials(record.Sku)}
          />
        </Tooltip>
        )}
        {_stage === 'material' && (
          <Tooltip title="Send back to Product" placement="top">
            <ActionButton
              key={`${record.Sku}-send-back-to-product`}
              shape="circle"
              type="ghost"
              icon={<BackIcon />}
              onClick={moveBackToProduct(record.Sku)}
            />
          </Tooltip>
        )}
        {_stage === 'material' && (
          <Tooltip title="Move to Complete" placement="top">
            <ActionButton
              key={`${record.Sku}-move-to-complete`}
              shape="circle"
              type="ghost"
              icon={<CheckIcon />}
              onClick={moveToComplete(record.Sku)}
            />
          </Tooltip>
        )}
        <Tooltip title="Add comment" placement="top">
          <ActionButton
            key={`${record.Sku}-add-comment`}
            shape="circle"
            type="ghost"
            icon={<CommentIcon />}
            onClick={addComment(record.Sku)}
          />
        </Tooltip>
      </FlexRow>
    }),
    width: '5%',
  });

  const columns = [
    NEW_PART_SKU_COLUMN,
    DESCRIPTION_COLUMN,
    RELEASE_DATE_COLUMN,
    CREATED_DATE_COLUMN,
    CREATED_BY_COLUMN,
    ACTION_COLUMN(stage),
  ];

  return (
    <ListWrapper>
      <ItemTable
        dataSource={filteredParts}
        columns={columns}
        rowKey="id"
        size="middle"
        onRow={(record) => ({
          onClick: onRowClick(record),
          style: { cursor: 'pointer' },
        })}
        pagination={false}
      />
    </ListWrapper>
  );
};

export default ProductDevList; 
