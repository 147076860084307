import styled from 'styled-components';
import theme from 'shared/theme';
import { BREAKPOINTS } from 'shared/grid/constants';
import Container from 'shared/containers/ContentContainer/styledComponents';
import { ConfigurationCol, ConfigurationRow } from 'shared/pageElements/styledComponents';
import { Menu } from 'antd';
import { rgba } from 'shared/palette/utils';
import { FlexColumn, FlexRow } from '../shared/containers/FlexContainer';
import { H6 } from '../shared/typography';

export const CustomerDetailsCol = styled(FlexColumn)`
  width: 100%;
  margin-right: 12px;
  align-items: flex-start;
`;

export const PartHeaderDetailsCol = styled(CustomerDetailsCol)`
  margin-bottom: 16px;
  max-width: 1500px;
`;

export const WrapperTopMargin = styled(Container)`
  width: 100vw;
  height: auto;
  
  background-color: ${theme.palette.neutral.transparent};
  max-width: ${(props) => (props.fixedWidth ? `${BREAKPOINTS.lg}px` : '100vw')};
  margin: ${(props) => (props.fixedWidth ? '0 auto' : '0')};
  margin-top: ${theme.spacing(10)};
  margin-left: ${theme.spacing(3)};
`;

WrapperTopMargin.displayName = 'WrapperTopMargin';

export const PageHeaderWrapper = styled(ConfigurationRow)`
  align-items: flex-start;
  flex-direction: column;
  @media ${theme.device.laptopM} {
    align-items: center;
  }
`;

export const CustomerEditPlaceholder = styled(FlexColumn)`
  margin: 100px 48px;
`;

export const PartFinderWrapper = styled(PageHeaderWrapper)`
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
`;

export const CustomerPricingWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PricingCol = styled(ConfigurationCol)`
    width: 100%;
    //max-height: 90vh;
    align-items: flex-start;
    margin: 0;
    padding-bottom: 100px;
`;

export const SiteMenuWrapper = styled(FlexRow)`
  width: 100%;
  background-color: ${theme.palette.neutral.white};
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  z-index: 2001;
`;

export const CustomerSiteLogo = styled.div`
  height: 48px;
  width: 48px; 
  background-image: url('/images/wildwood_logo_200.png'); 
  background-repeat: no-repeat; 
  background-position: center;
  background-size: cover;
  display: inline-block;
  position: relative; 
  top: 2px; 
  left: 16px; 
  margin-right: 24px;
  & img {
    object-fit: contain;
  }
`;
export const CustomerMenuWrapper = styled(SiteMenuWrapper)`
  padding-top: 16px;
  height: 48px;
  justify-content: flex-start;
  align-items: center;
`;
export const CustomerMenuTitle = styled.h2`
  margin: 0;
`;
export const CustomerPageTitle = styled.h2`
  flex-grow: 2;
  margin: 0;
  color: grey;
  padding-left: 8px;
  margin-left: 8px;
  border-left: 2px solid black;
`;

export const SiteMenuLogo = styled.button`
    //margin-left: 12px;
    height: 48px;
    width: 58px;
    border: none;
    background-image: ${(props: { siteImage: string }) => `url(${props.siteImage})`};
    background-size: contain;
    background-repeat: no-repeat;
`;
export const SiteMenu = styled(Menu)`
  flex: auto;
  flex-grow: 2;
  height: 48px;
  padding-left: 0;
  padding-right: 0;
  display: none;
  
  //position: sticky;
  //top: 0;
  //z-index: 200001;
  @media ${theme.device.laptop} {
    display: flex;
    & [data-menu-id$="-1-tmp-6"]{
      margin: 0 0 0 auto;
    }
  }
  @media print {
    display: none;
  }
`;

export const SiteMenuItem = styled(Menu.Item)`
  height: 100%;
  color: ${(props: any) => (props.activeColor ? theme.palette.info.hue : theme.palette.neutral.black)};
  & a {
    color: ${(props: any) => (props.activeColor ? theme.palette.info.hue : theme.palette.neutral.black)}
  }
}
`;

export const MenuActionButtonGroup = styled(FlexRow)`
  height: 46px;
  gap: 8px;
`;

export const MobileMenu = styled.div`

  @media ${theme.device.laptop} {
    display: none;
  }
  @media print {
    display: none;
  }
`;

export const MobileMenuHeader = styled(H6)`
  color: ${theme.palette.neutral.white};
  text-decoration: underline;
  margin-top: 24px;
`;

export const MobileMenuLink = styled.a`
  width: auto;
  height: 36px;
  
  display: inline-block;
  
  font-size: 16px;
  line-height: 1;
  color: ${theme.palette.neutral.black};
  background-color: ${rgba(theme.palette.neutral.white, 0.95)};
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
  padding-top: 10px;
  transition: all 0.1s linear;
  
  &:active {
    background-color: ${theme.palette.primary.hue};
    color: ${theme.palette.neutral.white};
  }
`;

export const MobileMenuRow = styled(FlexRow)`
  margin: 8px 24px 24px 24px;
  border: none;
  flex: none;
`;

export const LogoWrapper = styled.div`
  margin-top: 8px;
  margin-left: calc(50% - 124px);
`;

export const TestIndicator = styled.div`
  display: flex !important;
  justify-content: center;
  width: 132px;
  height: 36px;
  border-radius: 18px; 
  position: fixed;
  top: 48px;
  left: 0;
  background-color: ${theme.palette.error.hue};
  z-index:10000;
  animation: scroll 60s linear infinite;
  
  & h3 {
    color: white;
    font-size: 20px;
    font-weight: bold;
    width: auto;
    margin: 0;
    line-height: 34px;
  }
`;
