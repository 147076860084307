import React, { useState } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { IInventoryPart, IShipmentItem } from 'shared/types/dbRecords';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import PartNumberSearchSelect from 'pages/Orders/Components/OrderItems/OrderItemDetail/PartNumberSearchSelect';

interface IComponent {
  hasShipped: boolean; // this will be used to determine whether to load the quantity shipped or quantity open
  shipmentItem: IShipmentItem;
  customerParts: any[];
  inventoryItems: IInventoryPart[];
  shipCallback: (partId: string, value: number) => void;
  cancelCallback: (partId: string, value: number) => void;
  backorderCallback: (partId: string, value: number) => void;
  notesCallback: (partId: string, value: string) => void;
  updateCallback: (shipmentItem: IShipmentItem) => void;
}

const ComponentWrapper = styled(FlexRow)`
  width: 100%;
  padding: 8px 0;
  justify-content: space-between;
  gap: 12px;
  border-bottom: 1px solid lightgrey;
`;
const PartDescriptionWrapper = styled(FlexRow)`
  width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

const DetailNotesWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`;

const ShipQuantityWrapper = styled(FlexRow)`
  justify-content: space-between;
  gap: 24px;
`;

const QuantityInnerWrapper = styled(FlexRow)`
  border-radius: 8px;
  border: 1px solid lightgrey;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 4px 8px;
  gap: 12px;
`;

const PartNumber = styled.p`
  width: 116px;
  font-weight: bold;
  margin: 0;
`;
const PartDescription = styled.p`
  margin: 0;
`;

const ShipmentItem = ({
  hasShipped, shipmentItem, customerParts, inventoryItems, shipCallback, cancelCallback, backorderCallback, notesCallback, updateCallback,
}:IComponent) => {
  const [_shipmentItem, setShipmentItem] = useState<IShipmentItem>(shipmentItem);
  const onChangeShipQuantity = (value: number) => {
    shipCallback(shipmentItem.id, value);
  };
  const onChangeCancelQuantity = (value: number) => {
    cancelCallback(shipmentItem.id, value);
  };
  const onChangeBOQuantity = (value: number) => {
    backorderCallback(shipmentItem.id, value);
  };

  const onChangeNotes = (update: boolean = false) => (value: string) => {
    setShipmentItem({ ..._shipmentItem, notes: value });
    updateCallback({ ..._shipmentItem, notes: value });
  };

  const onChangeItemDescription = (value: string) => {
    if (!_shipmentItem.editable) return;
    setShipmentItem({ ..._shipmentItem, Description: value });
    updateCallback({ ..._shipmentItem, Description: value });
  };

  const onChangeItemUnitPrice = (value: number) => {
    setShipmentItem({ ..._shipmentItem, unitPrice: value });
    updateCallback({ ..._shipmentItem, unitPrice: value });
  };

  const onPartNumberChange = (partNumber: string) => {
    const part = find([...customerParts, ...inventoryItems], (c: any) => c.Sku === partNumber);
    if (!part) return;
    const updated = {
      ...shipmentItem,
      editable: !!partNumber.match(/^991/),
      Sku: partNumber,
      Description: part.Description,
      unitPrice: (part.price || part.UnitPrice || part.unitPrice),
    };
    setShipmentItem(updated);
    updateCallback(updated);
  };

  return (
    <ComponentWrapper>
      {shipmentItem && (
        <>
          <PartDescriptionWrapper>
            <PartNumberSearchSelect
              inventoryItems={inventoryItems}
              customerParts={customerParts}
              open={shipmentItem.open}
              partNumber={shipmentItem.Sku}
              changeCallback={onPartNumberChange}
              showLabel={false}
            />
            {/* <PartNumber>{shipmentItem.Sku}</PartNumber> */}
            <DetailNotesWrapper>
              <DetailInputWithCallback
                id={`shipment-item-description-${_shipmentItem.id}`}
                label=""
                placeholder="Select a part number to get the part desc."
                value={_shipmentItem.Description}
                callback={onChangeItemDescription}
                isLabel={false}
                disabled={false}
                noMargin
                width="auto"
                wrapperWidth="100%"
                minWidth="60%"
              />
              <DetailTextAreaWithCallback
                id={`shipment-item-note-${_shipmentItem.id}`}
                style={{
                  width: 500, height: 32, lineHeight: '16px', minHeight: 0,
                }}
                label=""
                placeholder="Add part notes here"
                value={_shipmentItem.notes}
                changeCallback={onChangeNotes(false)}
                blurCallback={onChangeNotes(true)}
              />
            </DetailNotesWrapper>
          </PartDescriptionWrapper>
          <ShipQuantityWrapper>
            <DetailNumberInputWithCallback
              id="shipment-item-unit-price"
              label="Unit Price"
              placeholder="$1.00"
              isCurrency
              // disabled={!_shipmentItem.editable}
              value={_shipmentItem.unitPrice}
              callback={onChangeItemUnitPrice}
              width="100px"
            />
            <QuantityInnerWrapper>
              <DetailNumberInputWithCallback
                id={`order-item-to-ship-quantity-${_shipmentItem.id}`}
                label="Ship"
                placeholder={1}
                value={_shipmentItem.quantityShipped}
                callback={onChangeShipQuantity}
                min={0}
                width="80px"
              />
              <DetailNumberInputWithCallback
                id={`order-item-to-ship-quantity-${_shipmentItem.id}`}
                label="B.O."
                placeholder={0}
                value={_shipmentItem.quantityBackordered || 0}
                callback={onChangeBOQuantity}
                min={0}
                width="80px"
              />
              <DetailNumberInputWithCallback
                id={`order-item-to-cancel-quantity-${_shipmentItem.id}`}
                label="Cancel"
                placeholder="0"
                value={_shipmentItem.quantityCanceled}
                callback={onChangeCancelQuantity}
                min={0}
                max={_shipmentItem.quantityOrdered - _shipmentItem.quantityShipped}
                width="80px"
              />
            </QuantityInnerWrapper>
          </ShipQuantityWrapper>
        </>
      )}
    </ComponentWrapper>
  );
};

export default ShipmentItem;
