import axios from 'axios/index';
import API_BASE_URL from './index';

const generateOrderWorkbook = async (cartItems: any[], customer: string) => {
  const response = await axios.post(`${API_BASE_URL}/orders/create-from-cart`, 
    {
      cartItems,
      customer,
    },
    {
      responseType: 'arraybuffer',
    });
  return response.data;
};

export default generateOrderWorkbook;
