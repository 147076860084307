import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker } from 'antd';

import useFirebase from 'vendor/Firebase';
import { CSVDownload } from 'react-csv';
import firebase from 'firebase';
import _ from 'lodash';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWithCallback';
import { CustomerDownloadButton } from '../../Customer/Components/styledComponents';
import { FlexColumn, FlexRow } from '../../../shared/containers/FlexContainer/styledComponents';
import { qboDateString } from '../../../shared/data/calendar';

const { RangePicker } = DatePicker;
const ParseButton = styled(Button)`
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
  margin-bottom: 4px;
  border-radius: 8px;
`;

const DownloadButton = styled(CustomerDownloadButton)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const PartsConsumedDownloader = () => {
  const thisMonthFirstDay = new Date();
  thisMonthFirstDay.setHours(0, 0, 0, 0);
  thisMonthFirstDay.setDate(1);
  const thisMonthLastDay = new Date();
  thisMonthLastDay.setHours(0, 0, 0, 0);
  thisMonthLastDay.setMonth(thisMonthLastDay.getMonth() + 1);
  thisMonthLastDay.setDate(1);
  thisMonthLastDay.setDate(thisMonthLastDay.getDate() - 1);

  const { firestore } = useFirebase();
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [fileName, setFilename] = useState('');
  const [startDate, setStartDate] = useState(thisMonthFirstDay);
  const [endDate, setEndDate] = useState(thisMonthLastDay);

  const onDownload = async (e: any) => {
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() - 1);
    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() + 1);
    const shipped = await firestore.collection('orders').where('completed', '==', true)
      .where('shipDate', '>', firebase.firestore.Timestamp.fromDate(newStartDate))
      .where('shipDate', '<', firebase.firestore.Timestamp.fromDate(newEndDate))
      .get();

    const shippedOrderItemDocs = await Promise.all(shipped.docs.map((d) => firestore.collection('order-items').doc(d.id).get()));
    const shippedOrderItems = _.flatten(shippedOrderItemDocs.map((d) => d.data().orderItems));

    const boms = _.flatten(shippedOrderItems.map((i) => i.bom));
    const parts = _.uniqBy(boms.map((b) => ({ Sku: b.Sku, Description: b.Description })), (p) => p.Sku);
    const csvParts = _.sortBy(parts, (p) => p.Sku).map((p) => [p.Sku, p.Description.trim().replace('"', '')]);
    console.log([['Sku', 'Description'], ...csvParts]);
    setFilename(`ConsumedParts_${qboDateString(startDate)}_${qboDateString(endDate)}`);
    setCsvData([['Sku', 'Description'], ...csvParts]);
  };

  const onChangeDate = (type: 'start'|'end') => (date: Date) => {
    if (type === 'start') setStartDate(date);
    else setEndDate(date);
  };

  return (
    <FlexColumn>
      {(!!csvData.length && !!fileName.length) && (
        <CSVDownload data={csvData} filename={fileName} />
      )}
      <FlexRow>
        <DetailDateWithCallback
          value={startDate}
          id="start-date-picker"
          label="Start date"
          callback={onChangeDate('start')}
          disabled={false}
          disableDates={false}
        />
        <DetailDateWithCallback
          value={endDate}
          id="end-date-picker"
          label="End date"
          callback={onChangeDate('end')}
          disabled={false}
          disableDates={false}
        />
      </FlexRow>
      <ParseButton
        key="consumed-bom-data-download-button"
        onClick={onDownload}
      >
        Download Consumed Parts Data
      </ParseButton>
    </FlexColumn>
  );
};

export default PartsConsumedDownloader;
