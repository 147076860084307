import React from 'react';
import ContentContainer from 'shared/containers/ContentContainer';
import { useRecoilValue } from 'recoil';
import {
  customerUserEmailsAtom,
} from 'shared/state/routingState';
import { ISiteContentPage } from './types';
import {
  CustomerSiteLogo,
  CustomerMenuWrapper,
  CustomerPageTitle,
  CustomerMenuTitle,
} from './styledComponents';
import ScopedComponent from '../shared/components/Utility/ScopedComponent';
import { currentPageTitleAtom } from '../shared/state/orderState';
import { currentCustomerAtom } from '../shared/state/customerState';

const ContentWrapper = ({ children }: ISiteContentPage) => {
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const currentPageTitle = useRecoilValue(currentPageTitleAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);

  return (
    <>
      <ContentContainer>
        <ScopedComponent whitelist={customerEmails.emails}>
          <CustomerMenuWrapper>
            <CustomerSiteLogo />
            <CustomerMenuTitle>{currentCustomer?.CompanyName ?? ''}</CustomerMenuTitle>
            {!!currentPageTitle.length && (
              <CustomerPageTitle>{currentPageTitle}</CustomerPageTitle>
            )}
          </CustomerMenuWrapper>
        </ScopedComponent>
        { children }
      </ContentContainer>
    </>
  );
};

export default ContentWrapper;
