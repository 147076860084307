import React, { useEffect, useState } from 'react';
import {
  Progress, Modal, Upload, Tooltip, Menu, Dropdown, Button,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { CSVDownload, CSVLink } from 'react-csv';
import {
  every, find, findIndex, first, groupBy, noop, omit,
} from 'lodash';
import { CaretDown, Upload as UploadIcon } from '@styled-icons/fa-solid';
import useFirebase from 'vendor/Firebase';
import bodyParser from 'shared/partParser/bodyParser';
import neckParser from 'shared/partParser/neckParser';
import { resolveArchetype } from 'shared/partParser/partResolver';
import { useRecoilValue, useRecoilState } from 'recoil';
import { PART_VIEWER_COLLECTION, uploadProgressAtom, partConfigTermsAtom, partConfigTermTypesAtom } from 'shared/state/partViewState';
import { useTestDataAtom } from 'shared/state/pricingState';
import { devLog } from 'shared/util/logging';
import styled, { keyframes } from 'styled-components';
import theme from 'shared/theme';
import { ICustomerPart } from 'shared/types/parts';
import { checkGeometricDifferences, configToDescription, descriptionToConfig, isSamePart, sanitizePartDescription } from '../../../shared/partParser/util';
import { CSVDownloadButton, DownloadButton } from '../../../shared/styledComponents/inputs';
import { ORDER_ITEMS_DB_COLLECTION, ORDERS_DB_COLLECTION } from '../../../shared/state/orderState';
import { IShopOrder } from '../../Orders/types';
import { IOrderItem } from '../../../shared/types/dbRecords';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const UploadButton = styled(Button)`
  flex-grow: 2;
  border-radius: 8px;
  min-width: 160px;
`;
const SaveSpinner = styled.div`
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-left: 32px;

  border-radius: 50%;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.primary.hue};

  animation: ${rotation} 1s linear infinite;
`;

export default () => {
  const { firestore } = useFirebase();
  const [processedPercent, setProcessedPercent] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useRecoilState(uploadProgressAtom);
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const configTermTypes = useRecoilValue(partConfigTermTypesAtom);
  const ordersDbCollection = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbCollection = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const partViewerDbCollection = useRecoilValue(PART_VIEWER_COLLECTION);
  const [csvData, setCsvData] = useState<any>([]);

  const onUploadParts = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result
        .split('\n')
        .slice(1)
        .map((d: string) => d.trim().replace(/"/g, '').split(','))
        .filter((d: string[]) => d.length > 1)
        .map((d: string[]) => ({ active: d[0] === 'Y', Sku: d[1], config: descriptionToConfig(d[2], configTerms, configTermTypes), volume: d[6], revisionNeeded: d[8] === 'Y' }));

      const partCount = data.length;
      let newPartCount = 0;
      setUploadInProgress(true);

      if (partCount > 1000) {
        Modal.confirm({
          title: 'Large Upload Detected',
          content: 'You are attempting to upload a large set of data. This operation may take several minutes, with periods of inactivity from the UI. Continue?',
          okText: 'Yes',
          cancelText: 'No',
          onCancel: () => {
            setUploadInProgress(false);
            return false;
          },
        });
      }

      const finishUp = () => {
        setProcessedPercent(100); // Set to 100% as all parts have been processed
        devLog('CustomerPartUploader', 97, 'Upload complete!');
        const cachedDataKeys = Object.keys(localStorage).filter((k: string) => k.match(/partViewerData$/));
        cachedDataKeys.forEach((k: string) => localStorage.removeItem(k));
        Modal.info({
          content: `${partCount - newPartCount} parts updated, ${newPartCount} added to database. Click OK to reload!`,
          okText: 'OK',
          onOk: () => {
            window.location.reload();
          },
        });
      };

      const updateRevisions = async (_revisionParts: any[]) => {
        const openOrderDocs = await firestore.collection(ordersDbCollection).where('completed', '==', false).get();
        const orderItemsDocs = await Promise.all(openOrderDocs.docs.map((d: any) => firestore.collection(orderItemsDbCollection).doc(d.id).get()));
        const orderItemsData = orderItemsDocs.map((d) => {
          const { orderItems } = d.data() as { orderItems: IOrderItem[] };
          return { id: d.id, orderItems };
        });

        let batch = firestore.batch();
        const batchSize = 500;
        let processed = 0;

        const revisionPromises = _revisionParts.map(async (part: any) => {
          const orderItemRecords = orderItemsData.filter((o: { id: string, orderItems: IOrderItem[] }) => 
            findIndex(o.orderItems.filter((i) => i), (i: IOrderItem) => i.Sku === part.Sku) > -1);

          if (orderItemRecords.length === 0) {
            processed += 1;
            return;
          }

          orderItemRecords.forEach((o: { id: string, orderItems: IOrderItem[] }, index: number) => {
            const revisedItems = [...o.orderItems];
            const revisionIndex = findIndex(o.orderItems, (p) => p.Sku === part.Sku);
            revisedItems[revisionIndex] = { ...o.orderItems[revisionIndex], revisionNeeded: part.revisionNeeded };
            
            const docRef = firestore.collection(orderItemsDbCollection).doc(o.id);
            batch.update(docRef, { orderItems: revisedItems });

            // Commit batch in chunks
            if ((index + 1) % batchSize === 0 || index === orderItemRecords.length - 1) {
              batch.commit();
              batch = firestore.batch(); // Reset batch
            }
          });

          processed += 1;
        });

        await Promise.all(revisionPromises);

        // Ensure any remaining batch operations are committed
        if (batch.commit) {
          await batch.commit();
        }

        finishUp();
      };

      const handleUpload = async () => {
        const revisions: any[] = [];
        let batch = firestore.batch();
        const batchSize = 500; // Firestore allows up to 500 operations per batch

        const processPromises = data.filter((p) => p).map(async (part: any, index: number) => {
          const archetype = resolveArchetype(part.config);
          const partCategory = archetype.text.match(/[G|B]N/i) ? 'neck' : 'body';
          const partRecord = { ...part, partCategory };

          try {
            const docRef = firestore.collection(partViewerDbCollection).doc(partRecord.Sku);
            
            const doc = await docRef.get();
            let docData = partRecord;
            if (!doc.exists) {
              newPartCount += 1;
            } else {
              docData = doc.data();
            }
            
            const updateData = { ...docData, ...partRecord };
            if (partRecord.volume !== '') {
              const vol = parseFloat(partRecord.volume).toFixed(3);
              updateData.volume = vol;
            }

            // Add to batch instead of setting directly
            batch.set(docRef, omit(updateData, ['Description']));

            if (part.revisionNeeded) {
              revisions.push(part);
            }

            // Commit batch in chunks to avoid hitting Firestore limits
            if ((index + 1) % batchSize === 0 || index === data.length - 1) {
              await batch.commit();
              // Create a new batch for the next set of operations
              batch = firestore.batch();
            }
          } catch (err) {
            devLog('JBDataUploader', 107, err);
          } finally {
            setProcessedPercent(Math.ceil(((index + 1) / data.length) * 100));
          }
        });

        await Promise.all(processPromises);

        if (revisions.length > 0) {
          Modal.confirm({
            content: `There are ${revisions.length} parts that have been revised. Do you want to update the order items with these revisions?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
              await updateRevisions(revisions);
              finishUp();
            },
            onCancel: () => {
              finishUp();
            },
          });
        } else {
          finishUp();
        }
      };

      handleUpload();
    };

    reader.readAsText(file);

    return false;
  };
  const onUploadOrder = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(1)
        .map((d: string) => d.trim().split(','));
      // .map((d: string[]) => d.filter((s: string) => s.match(/^[G|B][B|N]/)));

      const customer = data[0][3].split('_')[0];
      const partCandidates = data.map((d: string[]) => ({ sku: d[3], description: d[4] })).filter((d) => d && d.description);
      const bodyCandidates = partCandidates.filter((c: { sku: string, description: string }) => c.description.match(/^[G|B]B/));
      const neckCandidates = partCandidates.filter((c: { sku: string, description: string }) => c.description.match(/^[G|B]N/));

      const parsedBodies = bodyParser(bodyCandidates.map((c: { sku: string, description: string }) => [true, '', sanitizePartDescription(c.description), '', '', '']));
      const parsedNecks = neckParser(neckCandidates.map((c: { sku: string, description: string }) => [true, '', sanitizePartDescription(c.description), '', '', '']));

      const customerPartDocs = await firestore.collection(partViewerDataString).where('customer', '==', customer).get();
      const customerParts = customerPartDocs.docs.map((d) => d.data() as ICustomerPart);

      const newParts = [...parsedBodies, ...parsedNecks].map((parsedItem: any) => {
        // FIRST, check if this part has a twin, e.g., it exists already
        const twin = first(customerParts
          .filter((p) => !!configToDescription(p.config))
          .map((p) => (isSamePart(p, parsedItem) ? p : null))
          .filter((m) => m));

        // @ts-ignore -- twin is checked for definition before calling
        if (twin !== undefined) return { requested: configToDescription(twin.config), part: omit(twin, 'parent'), result: 'exists' };

        // NEXT, if no twin is found, check for a parent, e.g. a part that is geometrically equivalent
        const parentCandidates = customerParts
          .map((p) => {
            const geoDiff = checkGeometricDifferences(p, parsedItem);
            if (every(geoDiff)) {
              return p;
            }
            return null;
          }).filter((p) => p);

        if (parentCandidates.length > 0) {
          const parent = parentCandidates[0] as any;
          return {
            requested: configToDescription(parent.config),
            part: { ...parsedItem, Sku: 'NEW', parent: parent.Sku },
            result: 'new child',
          };
        }

        // If neither a twin nor a parent exists, this is a new part
        return {
          requested: configToDescription(parsedItem.config),
          part: { ...parsedItem, Sku: 'NEW' },
          result: 'new part',
        };
      });

      const records: string[][] = newParts.map((p) => [p.part.Sku as string, p.requested as string, p.result as string, p.part.parent as string || '']);
      setCsvData(records);
    };

    reader.readAsText(file);

    return false;
  };

  const onClickDownload = (e: any) => {
    setTimeout(() => {
      setCsvData([]);
    }, 100);
  };
  const importMenu = (
    <Menu onClick={noop}>
      <Menu.Item key="helm-data">
        <Upload
          accept=".txt, .csv"
          showUploadList={false}
          beforeUpload={onUploadParts}
        >
          <UploadButton type="primary" icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>HELM Parts</UploadButton>
        </Upload>
      </Menu.Item>
      <Menu.Item key="order-data">
        <Upload
          accept=".txt, .csv"
          showUploadList={false}
          beforeUpload={onUploadOrder}
        >
          <UploadButton icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>Customer Order</UploadButton>
        </Upload>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {uploadInProgress ? (
        <>
          {processedPercent >= 1 ? (
            <Progress type="circle" percent={processedPercent} width={64} style={{ marginLeft: 24 }} />
          ) : (
            <SaveSpinner />
          )}
        </>
      ) : (
        <>
          {csvData.length === 0 ? (
            <Tooltip placement="left" title="Use this to upload data in a csv format back into the pricing tool.">
              <Dropdown.Button
                trigger={['hover']}
                icon={<CaretDown style={{ width: 10, marginBottom: 4 }} />}
                onClick={noop}
                overlay={importMenu}
                type="default"
                placement="bottom"
              >
                Import
              </Dropdown.Button>
            </Tooltip>
          ) : (
            <CSVDownloadButton filename="Customer Order Data.csv" data={csvData || []} onClick={onClickDownload}>Download</CSVDownloadButton>
          )}
        </>
      )}
    </>
  );
};
