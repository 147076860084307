import React, { useEffect, useState } from 'react';
import {
  find, groupBy, includes, noop, sortBy,
} from 'lodash';
import { Menu } from 'antd';
import { CaretDown } from '@styled-icons/fa-solid';
import { useRecoilValue } from 'recoil';
import { CSVDownload } from 'react-csv';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { DownloadButton } from 'shared/styledComponents/inputs';
import QBOItem from 'shared/data/QBO/item';
import { IInventoryPart, IProductCode, IGlCode } from 'shared/types/dbRecords';
import { formatPercent } from 'shared/data';
import { glCodesAtom, productCodesAtom } from 'shared/state/utilState';
import InventoryExportModal from '../InventoryExportModal';

/**
 * Button component that is responsible for exporting inventory data as a csv file.
 */
export default () => {
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const productCodes = useRecoilValue(productCodesAtom);
  const glCodes = useRecoilValue(glCodesAtom);
  const today = new Date();
  // @ts-ignore
  const [csvData, setCsvData] = useState<any>([]);
  const [fileName, setFilename] = useState<string>('');
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

  const exportDetailReport = (critical: boolean = false, items = inventoryItems) => {
    const csvHeaders = ['Active', 'Sku', 'Description', 'QtyOnHand', 'QtyCounted', 'Difference', 'Purchase Unit', 'ProductCode', 'GLCode', 'Vendor', 'Cost', 'Sell', 'Margin', 'ReorderQty'];
    const rowData = items.filter((item: IInventoryPart) => (critical ? item.criticalPart : item)).map((item: IInventoryPart) => {
      if (!item.Description) console.log(item.Sku);
      const glCode = find(glCodes, (gl: IGlCode) => gl.Id === item.ExpenseAccountRef?.value);
      const notInventoried = QBOItem.partType(item.ProductCode?.productCode) === 'NonInventory';
      let margin = notInventoried ? 'N/A' : formatPercent((item.UnitPrice - item.PurchaseCost) / item.UnitPrice, 2, true);
      if (margin !== 'N/A' && item.UnitPrice === 0) margin = formatPercent(0, 2, true);
      return (
        [
          item.Active,
          item.Sku,
          item.Description?.replace(/[",]/g, ' ') || '',
          item.QtyOnHand,
          '',
          '',
          item.PurchasingUnit ? item.PurchasingUnit : 'EA',
          item.ProductCode?.productCode || '',
          glCode?.Name || '',
          item.PrefVendorRef?.name || '',
          item.PurchaseCost,
          item.UnitPrice,
          margin,
          item.reorderQty || 0,
        ]);
    });

    setFilename(`HELM_Inventory_Detail_${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate()}.csv`);
    setCsvData([csvHeaders, ...rowData]);
    setTimeout(() => {
      setCsvData([]);
    }, 1000);
  };
  const exportProductSummary = () => {
    const csvHeaders = ['ProductCode', 'ProductDescription', 'Amount', 'PercentOfTotal'];
    const totalValue = inventoryItems
      .filter((i) => !includes(['NONINVENTORY', 'ITEMMODIFIER'], i.ProductCode.productCode))
      .map((i) => i.QtyOnHand * i.PurchaseCost).reduce((a, b) => a + b, 0);
    const productGroups = groupBy(inventoryItems, (item: IInventoryPart) => item.ProductCode.productCode);
    const productTotals = Object.entries(productGroups)
      .filter((group) => !includes(['NONINVENTORY', 'ITEMMODIFIER'], group[0]))
      .map((group: [code: string, items: IInventoryPart[]]) => {
        const [code, items] = group;
        const productDescription = find(productCodes, (p: IProductCode) => p.productCode === code);
        const itemsTotal = items.map((i) => i.QtyOnHand * i.PurchaseCost).reduce((a, b) => a + b, 0);
        const percentTotal = formatPercent(itemsTotal / totalValue);
        return [code, (productDescription?.description || ''), itemsTotal, percentTotal];
      });

    setFilename(`HELM_Product_Summary_${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate()}.csv`);
    setCsvData([csvHeaders, ...sortBy(productTotals, (p) => p[0])]);
    setTimeout(() => {
      setCsvData([]);
    }, 1000);
  };

  const closeExportDetailModal = () => {
    setShowDetailModal(false);
  };

  const exportFilteredDetailReport = (exportInactiveParts: boolean, exportNonCountedParts: boolean, exportNonInventoryParts: boolean, exportCodes: string[]) => {
    const exportItems = inventoryItems
      .filter((i) => {
        if (exportInactiveParts) return i;
        return i.Active;
      })
      .filter((i) => i)
      .filter((i) => {
        if (exportNonCountedParts) return i;
        return !i.Children || i.Children.length === 0;
      })
      .filter((i) => i)
      .filter((i) => {
        if (exportNonInventoryParts) return i;
        return i.Type === 'Inventory';
      })
      .filter((i) => i)
      .filter((i) => {
        if (exportNonInventoryParts) return i.ProductCode?.AssetAccountRef?.name !== 'Inventory Asset';
        return includes(exportCodes, i.ProductCode?.productCode);
      });

    exportDetailReport(false, exportItems);
    setShowDetailModal(false);
  };

  const onExportContext = (context: { key: 'detail'|'productCode' }) => {
    if (context.key === 'detail') {
      // exportDetailReport();
      setShowDetailModal(true);
    } else if (context.key === 'productCode') {
      exportProductSummary();
    } else if (context.key === 'critical') {
      exportDetailReport(true);
    }
  };
  const exportMenu = (
    <Menu onClick={onExportContext}>
      <Menu.Item key="detail">
        Inventory Detail
      </Menu.Item>
      <Menu.Item key="critical">
        Critical Parts
      </Menu.Item>
      <Menu.Item key="productCode">
        Product Summary
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
  }, []);

  return (
    <>
      {(!!csvData.length && !!fileName.length) && (
        <CSVDownload data={csvData} filename={fileName} />
      )}
      <InventoryExportModal showModal={showDetailModal} closeCallback={closeExportDetailModal} syncCallback={exportFilteredDetailReport} />
      <DownloadButton
        trigger={['click']}
        icon={<CaretDown style={{ width: 10, marginBottom: 4, marginLeft: -5 }} />}
        onClick={noop}
        overlay={exportMenu}
        type="primary"
      >
        Export
      </DownloadButton>
    </>
  );
};
