import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Badge } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { shoppingCartAtom } from 'shared/state/pricingState';
import theme from 'shared/theme';
import ShoppingCartModal from './ShoppingCartModal';

const CartButton = styled(Button)`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  // border: px solid ${theme.palette.neutral.L200};
  
  &:hover {
    background: ${theme.palette.neutral.L100};
    border-color: ${theme.palette.neutral.L300};
  }
  
  &:focus {
    background: white;
    border-color: ${theme.palette.neutral.L200};
  }

  .anticon {
    font-size: 24px;
    color: ${theme.palette.neutral.black};
  }
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background: #E53935;
    font-size: 14px;
    font-weight: 500;
    min-width: 24px;
    height: 24px;
    padding: 0 6px;
    line-height: 24px;
    border-radius: 12px;
    box-shadow: none;
  }
`;

export default () => {
  const cartItems = useRecoilValue(shoppingCartAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <StyledBadge count={cartItems.reduce((sum, item) => sum + item.quantity, 0)} offset={[-8, 0]} showZero={false}>
        <CartButton
          type="default"
          icon={<ShoppingCartOutlined />}
          onClick={() => setIsModalOpen(true)}
        />
      </StyledBadge>
      <ShoppingCartModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}; 
