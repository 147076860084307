import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CopyAll } from '@styled-icons/material';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import {
  bomEditedAtom,
  copiedBomPartNumberAtom,
  PART_BOM_COLLECTION,
  partBomItemsAtom,
  showBomCopyDrawerAtom,
} from 'shared/state/partViewState';
import theme from 'shared/theme';
import { priceSearchQueryAtom } from 'shared/state/pricingState';
import { IBomItem } from 'shared/types/dbRecords';
import { cleanMeta } from 'shared/text';
import useFirebase from 'vendor/Firebase';
import BomCopyDrawer from '../PartBom/BomCopyDrawer';

const ComponentButton = styled(Button)`
  border-radius: 8px;
`;
const CopyIcon = styled(CopyAll)`
  color: ${theme.palette.primary.hue};
  width: 20px;
  height: 20px;
`;

interface IComponent {
  partDescription: string;
}
const CopyBomButton = ({ partDescription }: IComponent) => {
  const { firestore } = useFirebase();
  const partBomDbCollection = useRecoilValue(PART_BOM_COLLECTION);
  const setPricingSearchQuery = useSetRecoilState(priceSearchQueryAtom);
  const setShowBomCopyDrawer = useSetRecoilState(showBomCopyDrawerAtom);
  const setBomItems = useSetRecoilState(partBomItemsAtom);
  const copiedBomPartNumber = useRecoilValue(copiedBomPartNumberAtom);
  const setBomEdited = useSetRecoilState(bomEditedAtom);
  const [description, setDescription] = useState<string>(partDescription);
  const [_preQueryString, setPreQueryString] = useState<string>('');

  const onSelect = () => {
    setPricingSearchQuery('');
    sessionStorage.removeItem('pricing.query.string');
    firestore.collection(partBomDbCollection).doc(copiedBomPartNumber).get().then((doc) => {
      const data = doc.data() as { Description: string, bom: IBomItem[] };
      const bom = data?.bom;
      if (!bom) {
        return Modal.error({
          title: 'No BOM Available',
          content: 'Whoops! The BOM for this part is not available. Please try a different part.',
        });
      }
      setBomEdited(true);
      setBomItems(bom || []);
    });
  };
  const onClick = (e: any) => {
    // @ts-ignore
    // const preQueryString = description.split('_').map((s) => s.split('/')).join(', ');
    // setPricingSearchQuery(preQueryString);
    setPreQueryString(description.replace(/[_\/]/g, ', '));
    setShowBomCopyDrawer(true);
  };

  useEffect(() => {
    setDescription(cleanMeta(partDescription));
  }, [partDescription]);

  return (
    <>
      <BomCopyDrawer key="copy-bom-from-part-drawer" callback={onSelect} partDescription={description} searchQuery={_preQueryString} />
      <ComponentButton onClick={onClick} type="default" icon={<CopyIcon />}>Copy BOM</ComponentButton>
    </>
  );
};

export default CopyBomButton;
