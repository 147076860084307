import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { includes, noop } from 'lodash';
import { FlexRow } from 'shared/containers/FlexContainer';
import { currentInventoryPartAtom, INVENTORY_ITEMS_COLLECTION } from 'shared/state/inventoryState';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import QBOItem from 'shared/data/QBO/item';
import { formatPrice } from 'shared/data';
import { IInventoryChild, IInventoryPart } from 'shared/types/dbRecords';
import useFirebase from 'vendor/Firebase';
import { mapInventoryData, pricingPathFromPartType } from 'shared/data/pricing';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
  gap: 8px;
`;

interface IComponent {
  disabled: boolean;
  partType: string;
}
const InventoryPartCosting = ({ partType, disabled }: IComponent) => {
  const { firestore, database } = useFirebase();
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const [_disabled, setDisabled] = useState<boolean>(disabled);
  const [_partType, setPartType] = useState<string>(partType);
  const [margin, setMargin] = useState<string>('');
  const inventoryPartsDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);

  const updatePricingDatabase = async (_partState: IInventoryPart, value?: number) => {
    const dbPath = pricingPathFromPartType(_partState.Description.substring(0, 3));
    const data = await database.ref(`${dbPath}/items`).once('value');
    const partRecords = data.val();
    const updatedRecords = mapInventoryData(partRecords, [{
      Sku: _partState.Sku,
      Description: _partState.Description,
      PurchaseCost: _partState.PurchaseCost,
      UnitPrice: value || _partState.UnitPrice,
    }]);
    await database.ref(`${dbPath}/items`).set(updatedRecords);
  };

  const onPartSellChange = async (value: number) => {
    setPartState({ ...partState, UnitPrice: value });
    const m = ((value - partState.PurchaseCost) / value).toFixed(4);
    setMargin(`${(m * 100).toFixed(2)}%`);
    /*
    if the part has children, update the sell price of each child to match the parent sell price.
     */
    if (partState.Children?.length) {
      await Promise.all(partState.Children.map((c: IInventoryChild) => firestore.collection(inventoryPartsDbString).doc(c.sku).update({ UnitPrice: value })));
    }
    /*
    if the part matches a wood type, remap the unit sell price to the part in the pricing database,
    so that the next time we open a part with that component in it, the price will be up-to-date.
     */
    if (includes(['DT_', 'CT_', 'GNB', 'BNB', 'BBK', 'GFB', 'BFB'], partState.Description.substring(0, 3))) {
      await updatePricingDatabase(partState, value);
    }
  };

  const onPurchaseUnitChange = (unit: string) => {
    setPartState({ ...partState, PurchasingUnit: unit });
  };
  const onPartCostChange = async (value: number) => {
    setPartState({ ...partState, PurchaseCost: value });
    const m = ((partState.UnitPrice - value) / partState.UnitPrice).toFixed(4);
    setMargin(`${(m * 100).toFixed(2)}%`);
    /*
    if the part has a parent, we need to update the parent's new average cost based on the updated cost of this item.
    Fetch all children from the parent document, calculate the new average price, then update the parent.
     */
    if (partState.Parent) {
      const parentDoc = await firestore.collection(inventoryPartsDbString).doc(partState.Parent.Sku).get();
      const parent = parentDoc.data();
      const allChildDocs = await Promise.all(parent.Children.map((c: IInventoryChild) => firestore.collection(inventoryPartsDbString).doc(c.sku).get()));
      const allChildren = allChildDocs.map((c) => c.data());
      const childCosts = [...allChildren.filter((c) => c.Sku !== partState.Sku).map((c: IInventoryPart) => c.PurchaseCost), value];
      const averageCost = parseFloat((childCosts.reduce((a, b) => a + b, 0) / childCosts.length).toFixed(2));
      // store the average cost in the parent part, but keep the purchase cost static
      await firestore.collection(inventoryPartsDbString).doc(partState.Parent.Sku).update({ averageCost, PurchaseCost: value });
    }
    if (includes(['DT_', 'CT_', 'GNB', 'BNB', 'BBK', 'GFB', 'BFB'], partState.Description.substring(0, 3))) {
      await updatePricingDatabase(partState, value);
    }
  };

  useEffect(() => {
    const m = ((partState.UnitPrice - partState.PurchaseCost) / partState.UnitPrice).toFixed(4);
    setMargin(`${(m * 100).toFixed(2)}%`);
  }, [partState]);

  useEffect(() => {
    const inventoryType = QBOItem.partType(partType || '');
    setDisabled(disabled);
    setPartType(inventoryType);
  }, [disabled, partType]);

  return (
    <>
      <ComponentWrapper>
        {/* {_partType !== 'Non-Inventory' && ( */}
        <DetailNumberInputWithCallback
          id="unit-price"
          label="Unit Sell"
          placeholder="$1.00"
          value={formatPrice(partState.UnitPrice, 3)}
          decimalPlaces={3}
          callback={onPartSellChange}
          isCurrency
          disabled={partState.Parent}
        />
        {/* )} */}
        <DetailNumberInputWithCallback
          id="unit-cost"
          label="Purchase Price"
          placeholder="$1.00"
          value={formatPrice(partState.PurchaseCost, 3)}
          decimalPlaces={3}
          callback={onPartCostChange}
          isCurrency
          useHelper={partState.Children?.length}
        />
        {partState.Children?.length && (
          <DetailNumberInputWithCallback
            id="average-cost"
            label="Avg. Cost"
            placeholder="$1.00"
            value={formatPrice(partState.averageCost, 3)}
            decimalPlaces={3}
            callback={noop}
            isCurrency
            useHelper={!!partState.Children.length}
            disabled
            helper="This is the average cost of all sub-items, based on their purchase weight."
          />
        )}
        <DetailInputWithCallback
          id="purchase-unit"
          label={`${partState.Children?.length ? 'Allocation' : 'Purchase'} Unit`}
          placeholder="EA"
          value={partState.PurchasingUnit}
          callback={onPurchaseUnitChange}
          disabled={_disabled}
        />
        {_partType === 'Inventory' && (
        <DetailInputWithCallback
          disabled
          id="unit-margin"
          label="Margin"
          placeholder="20%"
          value={margin}
          callback={noop}
        />
        )}
        <DetailNumberInputWithCallback
          disabled
          id="unit-last-purchase-cost"
          label="Last Purchase Price"
          placeholder="$1.00"
          value={formatPrice(partState.PurchaseCost, 3)}
          decimalPlaces={3}
          callback={noop}
          isCurrency
        />
      </ComponentWrapper>
    </>
  );
};

export default InventoryPartCosting;
