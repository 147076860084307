import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Drawer, Button, InputNumber, Card, Tooltip, Typography } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DeleteOutlined, DownOutlined, UpOutlined, PlusOutlined, ShoppingCartOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { shoppingCartAtom, ICartItem } from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import { formatPrice } from 'shared/data';
import theme from 'shared/theme';
import generateOrderWorkbook from 'shared/data/HELM/order';
import { rgba } from 'shared/palette/utils';
import { breakPartDescription } from 'shared/partParser/util';
import { currentCustomerAtom } from 'shared/state/customerState';

const { Title, Text } = Typography;

const DrawerContent = styled(FlexRow)`
  height: 100%;
  padding: 0;
  display: flex;
`;

const CartItems = styled(FlexColumn)`
  height: 100%;
  width: 75%;
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  justify-content: flex-start;
`;

const DownloadButton = styled(Button)`
  border-radius: 8px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .anticon {
    font-size: 24px;
    color: ${theme.palette.neutral.white};
  }
`;

const CartItemWrapper = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
  padding: 24px;
  border-radius: 12px;
  background-color: ${(props: { index: number }) => (props.index % 2 === 0 ? 'white' : rgba(theme.palette.neutral[100], 0.15))};
  margin-bottom: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  gap: 8px;
`;

const CartItemHeader = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const ItemInfo = styled(FlexColumn)`
  align-items: flex-start;
  text-align: left;
  flex: 1;
`;

const CartItemSku = styled.div`
  font-size: 14px;
  font-weight: bolder;
  color: ${theme.palette.primary.hue};
`;

const CartItemDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.neutral.black};
`;

const CartItemPrice = styled.div`
  font-size: 16px;
  color: ${theme.palette.primary.hue};
  font-weight: 500;
`;

const CartPriceWrapper = styled(FlexColumn)`
  align-items: flex-end;
  margin-bottom: 24px;
`;

const CartSummaryPrice = styled(CartItemPrice)`
  font-size: 24px;
  font-weight: 600;
`;

const CartSummaryPriceNote = styled(Text)`
  font-size: 16px;
  color: ${theme.palette.neutral[600]};
`;

const CartItemControls = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DeleteButton = styled(Button)`
  color: ${theme.palette.error.hue};
  &:hover {
    color: ${theme.palette.error.D100};
  }
`;

const NotesButton = styled(Button)`
  padding: 0;
  height: auto;
  color: ${theme.palette.neutral[600]};
  
  &:hover {
    color: ${theme.palette.primary.hue};
  }
`;

const StyledDrawer = styled(Drawer)`
  z-index: 1000000;
  .ant-drawer-header {
    padding: 16px 24px;
    border-bottom: 1px solid ${theme.palette.neutral[200]};
  }
  
  .ant-drawer-body {
    padding: 0;
  }
  
  .ant-drawer-content-wrapper {
    height: 100vh !important;
  }
`;

const CartSection = styled(Card)`
  width: 90%;
  gap: 24px;
  margin-bottom: 32px;
  border-radius: 12px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: white;
  padding: 16px;
`;

const SectionTitle = styled(Title)`
  margin: 0 !important;
`;

const CustomerName = styled(Text)`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${theme.palette.neutral[600]};
`;

const CartHeader = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 24px;
  margin: 0 auto 2rem auto;
`;

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IExpandedNotes {
  [key: string]: boolean;
}

export default ({ isOpen, onClose }: IProps) => {
  const [cartItems, setCartItems] = useRecoilState(shoppingCartAtom);
  const [expandedNotes, setExpandedNotes] = useState<IExpandedNotes>({});
  const [isDownloading, setIsDownloading] = useState(false);
  const currentCustomer = useRecoilValue(currentCustomerAtom);

  const groupedItems = useMemo(() => {
    const bodies = cartItems.filter((item) => item.Description.match(/^(gb|bb)/i));
    const necks = cartItems.filter((item) => item.Description.match(/^(gn|bn)/i));
    const others = cartItems.filter((item) => !item.Description.match(/^(gb|bb|gn|bn)/i));
    return { bodies, necks, others };
  }, [cartItems]);

  useEffect(() => {
    const initialExpandedState = cartItems.reduce((acc, item) => ({
      ...acc,
      [item.Sku]: !!item.notes
    }), {});
    setExpandedNotes(initialExpandedState);
  }, [cartItems]);

  const updateQuantity = (index: number, quantity: number) => {
    const newItems = [...cartItems];
    newItems[index] = { ...newItems[index], quantity };
    setCartItems(newItems);
    localStorage.setItem('shoppingCart', JSON.stringify(newItems));
  };

  const updateNotes = (index: number, notes: string) => {
    const newItems = [...cartItems];
    newItems[index] = { ...newItems[index], notes };
    setCartItems(newItems);
    localStorage.setItem('shoppingCart', JSON.stringify(newItems));
    
    if (notes) {
      setExpandedNotes({
        ...expandedNotes,
        [newItems[index].Sku]: true
      });
    }
  };

  const removeItem = (index: number) => {
    const newItems = cartItems.filter((_, i) => i !== index);
    setCartItems(newItems);
    localStorage.setItem('shoppingCart', JSON.stringify(newItems));
  };

  const toggleNotes = (sku: string) => {
    setExpandedNotes({
      ...expandedNotes,
      [sku]: !expandedNotes[sku]
    });
  };

  const getNotesIcon = (item: ICartItem) => {
    if (!expandedNotes[item.Sku] && !item.notes) {
      return <PlusOutlined />;
    }
    return expandedNotes[item.Sku] ? <UpOutlined /> : <DownOutlined />;
  };

  const downloadOrder = async () => {
    try {
      setIsDownloading(true);
      const splitCartItems = cartItems.map((item) => ({
        ...item,
        Description: breakPartDescription(item.config),
      }));

      const binaryData = await generateOrderWorkbook(splitCartItems, currentCustomer.DisplayName);
      const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const date = new Date().toISOString().split('T')[0];
      const filename = `${date}_${currentCustomer.DisplayName}_Wildwood_Order.xlsx`;
      
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      console.log('CLicking link...');
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setCartItems([]);
      localStorage.setItem('shoppingCart', JSON.stringify([]));
      onClose();
    } catch (error) {
      console.error('Error downloading order:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const total = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);

  const renderCartSection = (items: ICartItem[], title: string) => {
    if (items.length === 0) return null;
    
    return (
      <CartSection title={title} bordered={false}>
        {items.map((item, index) => {
          const originalIndex = cartItems.findIndex((i) => i.Sku === item.Sku);
          return (
            <CartItemWrapper key={item.Sku} last={index === items.length - 1} index={index}>
              <CartItemHeader>
                <ItemInfo>
                  <CartItemSku>{item.Sku}</CartItemSku>
                  <CartItemDescription>{item.Description}</CartItemDescription>
                </ItemInfo>
                <CartItemPrice>{formatPrice(item.price * item.quantity)}</CartItemPrice>
              </CartItemHeader>
              <CartItemControls>
                <FlexRow style={{ gap: '12px', alignItems: 'center' }}>
                  <div>Quantity:</div>
                  <InputNumber
                    min={1}
                    value={item.quantity}
                    onChange={(value) => updateQuantity(originalIndex, value || 1)}
                  />
                  <NotesButton 
                    type="text"
                    onClick={() => toggleNotes(item.Sku)}
                  >
                    Notes 
                    {getNotesIcon(item)}
                  </NotesButton>
                </FlexRow>
                <DeleteButton
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => removeItem(originalIndex)}
                />
              </CartItemControls>
              {expandedNotes[item.Sku] && (
                <DetailTextAreaWithCallback
                  height="80px"
                  value={item.notes}
                  changeCallback={(value) => updateNotes(originalIndex, value)}
                  blurCallback={(value) => updateNotes(originalIndex, value)}
                  placeholder="Add notes..."
                />
              )}
            </CartItemWrapper>
          );
        })}
      </CartSection>
    );
  };

  return (
    <StyledDrawer
      placement="top"
      open={isOpen}
      onClose={onClose}
      height="100vh"
      destroyOnClose
      closable
    >
      <CartHeader>
        <TitleSection>
          <SectionTitle level={2}>Order Summary</SectionTitle>
          <CustomerName type="primary">{currentCustomer.CompanyName}</CustomerName>  
        </TitleSection>
        <TotalSection>
          <CartPriceWrapper>
            <CartSummaryPrice level={3}>{`Est. Total: ${formatPrice(total)}`}</CartSummaryPrice>
            <CartSummaryPriceNote type="secondary" style={{ maxWidth: '400px', textAlign: 'right', fontSize: '0.9em' }}>
              This is an estimate based on the quantity of items in your cart, not including any upgrades or changes to parts, listed here.
            </CartSummaryPriceNote>
          </CartPriceWrapper>
          <DownloadButton type="primary" icon={isDownloading ? <LoadingOutlined /> : <ShoppingCartOutlined />} onClick={downloadOrder}>
            Download Order
          </DownloadButton>
        </TotalSection>
      </CartHeader>

      <DrawerContent>
        <CartItems>
          {cartItems.length === 0 ? (
            <FlexColumn style={{ alignItems: 'center', padding: '24px' }}>
              <div>Your cart is empty</div>
            </FlexColumn>
          ) : (
            <>
              {renderCartSection(groupedItems.bodies, 'Bodies')}
              {renderCartSection(groupedItems.necks, 'Necks')}
              {renderCartSection(groupedItems.others, 'Other Items')}
            </>
          )}
        </CartItems>
      </DrawerContent>
    </StyledDrawer>
  );
}; 
